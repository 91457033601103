import type { z } from "zod";

export async function fetcher<S extends z.ZodTypeAny>(
	url: string,
	schema: S,
	options?: RequestInit,
): Promise<z.output<S>> {
	return fetch(url, {
		headers: {
			"Content-Type": "application/json",
		},
		credentials: "include",
		...options,
	}).then(async (res) => {
		if (res.status === 204) {
			return;
		}

		if (!res.ok) {
			if (import.meta.env.MODE === "development") {
				console.error(
					`An error occurred fetching '${url}'\n${await res.text()}`,
				);
			}

			throw new Error("An error occurred");
		}

		const json = await res.json();

		const parsedJson = schema.safeParse(json);

		if (parsedJson.success === false) {
			if (import.meta.env.MODE === "development") {
				console.error(
					`An error occurred validating '${url}'\n${JSON.stringify(json)}\n${
						parsedJson.error
					}`,
				);
			}

			throw new Error("An error occurred");
		}

		return parsedJson.data as z.output<S>;
	});
}
