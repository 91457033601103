import { BankIcon } from "../../assets/bank";
import { PolygonIcon } from "../../assets/crypto/polygon";
import { cn } from "../../lib/cn";
import type { ExternalAccount } from "../../schemas/external-account";

const iconMap = {
	polygon: <PolygonIcon className="w-5 h-5 fill-neutral-700" />,
};

type Props = {
	account: ExternalAccount;
	action?: React.ReactNode;
	className?: string;
};

export function RenderAccount({ account, action = null, className }: Props) {
	return (
		<div className={cn("flex items-center justify-between py-2", className)}>
			<div className="flex items-center space-x-4">
				<div className="p-2 bg-black/10 rounded-md">
					{account.object === "bank-account" ? (
						<BankIcon className="w-5 h-5 fill-neutral-700" />
					) : (
						iconMap[account.network]
					)}
				</div>
				{account.object === "bank-account" ? (
					<div className="flex flex-col items-start">
						<code className="text-sm">{account.accountOwnerName}</code>
						<code className="text-sm">
							{account.iban} / {account.bic}
						</code>
					</div>
				) : (
					<div className="flex flex-col items-start">
						<code className="text-sm">Wallet</code>
						<code className="text-sm">{account.address}</code>
					</div>
				)}
			</div>
			{action}
		</div>
	);
}
