import { z } from "zod";

export const linkStatusSchema = z.enum(["enabled", "disabled"]);

export type LinkStatus = z.infer<typeof linkStatusSchema>;

export const linkSchema = z.object({
	id: z.string(),
	name: z.string(),
	amount: z.number(),
	currency: z.string(),
	status: linkStatusSchema,
	// metadata: z.record(z.string()).nullable(),
	createdAt: z.coerce.date(),
	updatedAt: z.coerce.date(),
});

export type Link = z.infer<typeof linkSchema>;
