import { getAccessToken } from "@privy-io/react-auth";
import { queryOptions, useQuery } from "@tanstack/react-query";
import { fetcher } from "../lib/fetcher";
import { externalAccountSchema } from "../schemas/external-account";
import {
	type PayoutStatus,
	payoutSchema,
	payoutTimelineSchema,
} from "../schemas/payout";

const statusLabels: Record<PayoutStatus, string> = {
	INITIATED: "Payout initiated",
	SENT: "Your funds have been sent to your account",
	PROCESSING: "Payout processing",
	SUBMITTED: "Payout submitted",
	COMPLETED: "Your funds have arrived in your account",
	CANCELED: "Payout has been canceled",
	FAILED: "Payout has failed",
	RETURNED: "Your funds have been returned",
	REFUNDED: "Your funds have been refunded",
};

const ignoredStatus: PayoutStatus[] = ["INITIATED", "PROCESSING", "SUBMITTED"];

async function getPayout(id: string) {
	const token = await getAccessToken();

	if (!token) {
		throw new Error("Unauthorized");
	}

	const payout = await fetcher(
		`${import.meta.env.VITE_API_URL}/dashboard/withdraw/${id}`,
		payoutSchema.extend({
			account: externalAccountSchema.nullable(),
			timeline: payoutTimelineSchema,
		}),
		{
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		},
	);

	const sentAt = payout.timeline.find((t) => t.status === "SENT");
	const completedAt = payout.timeline.find((t) => t.status === "COMPLETED");

	return {
		...payout,
		sentAt: sentAt ? new Date(sentAt.timestamp) : null,
		completedAt: completedAt ? new Date(completedAt.timestamp) : null,
		timeline: payout.timeline
			.filter((t) => !ignoredStatus.includes(t.status))
			.map((t) => ({
				...t,
				label: statusLabels[t.status],
			}))
			.reverse(),
	};
}

export function payoutOptions(id: string) {
	return queryOptions({
		queryKey: ["payout", id],
		queryFn: () => getPayout(id),
		staleTime: 5 * 1000,
	});
}

export function usePayout(id: string) {
	const { data, ...query } = useQuery(payoutOptions(id));

	return {
		payout: data,
		...query,
	};
}
