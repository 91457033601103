import { getAccessToken } from "@privy-io/react-auth";
import { useMutation } from "@tanstack/react-query";
import { z } from "zod";
import { queryClient } from "../../layouts/_root";
import { fetcher } from "../../lib/fetcher";

export function useDeleteExternalAccount() {
	return useMutation({
		mutationKey: ["delete-external-account"],
		mutationFn: async (id: string) => {
			return fetcher(
				`${import.meta.env.VITE_API_URL}/dashboard/account/${id}`,
				z.any(),
				{
					method: "DELETE",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${await getAccessToken()}`,
					},
				},
			);
		},
		onMutate: async (id) => {
			await queryClient.cancelQueries({ queryKey: ["external-accounts"] });

			const prev = queryClient.getQueryData(["external-accounts"]);

			if (!Array.isArray(prev)) {
				return;
			}

			queryClient.setQueryData(["external-accounts"], () => {
				return prev.filter((account) => account.id !== id);
			});

			return { prev };
		},
		onError: (_, __, context) => {
			queryClient.setQueryData(["external-accounts"], context?.prev);

			alert("An error occurred deleting your bank details. Please try again.");
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: ["external-accounts"] });
		},
	});
}
