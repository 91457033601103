import dayjs from "dayjs";
import { Line, LineChart, ResponsiveContainer, Tooltip, YAxis } from "recharts";
import { useChartData } from "../../api/useChartData";
import { numberFormatter } from "../../lib/number";
import { CustomTooltip } from "../charts/tooltip";
import { DataCard } from "./data-card";

export function GrossRevenue() {
	const { chart } = useChartData();

	return (
		<DataCard title="Gross revenue" className="col-span-12 2xl:col-span-8">
			<div className="flex flex-col">
				<div className="flex">
					<ResponsiveContainer className="" width="100%" height={300}>
						<LineChart height={300} data={chart}>
							<YAxis
								axisLine={false}
								tickLine={false}
								tickFormatter={(value) =>
									numberFormatter(value / 100, {
										currency: "USD",
										style: "currency",
										notation: "compact",
									})
								}
								className="text-sm text-neutral-500"
							/>
							<Line
								animationDuration={300}
								animationEasing="ease-in-out"
								dataKey="value"
								stroke="#1a9468"
								strokeWidth={1.5}
								activeDot={false}
								dot={false}
							/>
							<Tooltip
								animationDuration={150}
								animationEasing="ease-in-out"
								cursor={{
									stroke: "#d4d4d4",
									strokeWidth: 1,
									strokeDasharray: "2",
								}}
								position={{ y: 30 }}
								content={
									<CustomTooltip
										formatter={(value) => {
											const formatted = numberFormatter(
												value === 0 ? 0 : value / 100,
											);

											return `USDC ${formatted}`;
										}}
									/>
								}
							/>
						</LineChart>
					</ResponsiveContainer>
				</div>
				{chart.length > 0 && (
					<div className="pl-[75px] flex justify-between items-start text-sm text-neutral-500">
						<span>{dayjs(chart[0].date).format("MMM DD")}</span>
						<span>{dayjs(chart[chart.length - 1].date).format("MMM DD")}</span>
					</div>
				)}
			</div>
		</DataCard>
	);
}
