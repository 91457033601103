import { getAccessToken, useLogin, usePrivy } from "@privy-io/react-auth";
import { useMutation } from "@tanstack/react-query";
import {
	createRoute,
	redirect,
	useNavigate,
	useSearch,
} from "@tanstack/react-router";
import { z } from "zod";
import { InflowIcon } from "../assets/inflow";
import { rootRoute } from "../layouts/_root";
import { getUser } from "../lib/get-user";

const loginSearchParamsSchema = z.object({
	next: z.string().optional(),
});

export const loginRoute = createRoute({
	getParentRoute: () => rootRoute,
	path: "/login",
	component: LoginPage,
	beforeLoad: async ({ search }) => {
		return {
			next: search.next,
		};
	},
	loader: async ({ context }) => {
		const user = await getUser(context.accessToken);

		if (!user) {
			return;
		}

		if (user?.legalName === null) {
			throw redirect({
				to: "/merchant",
				search: {
					next: context.next || "/",
				},
			});
		}

		// waiting to get history -1, so this is a custom workaround to get history
		// https://github.com/TanStack/router/discussions/305#discussioncomment-3351924
		if (context.next === "/links/new") {
			throw redirect({
				to: context.next,
				search: {
					back: "/",
					products: [],
				},
			});
		}

		throw redirect({
			to: context.next || "/",
		});
	},
	validateSearch: loginSearchParamsSchema,
});

function LoginPage() {
	const { next } = useSearch({ from: "/login" });
	const navigate = useNavigate({ from: "/login" });
	const { mutateAsync: signup } = useMutation({
		mutationKey: ["signup"],
		mutationFn: async ({
			token,
			payload,
		}: {
			token: string;
			payload: { email: string; wallet: string };
		}) => {
			const response = await fetch(
				`${import.meta.env.VITE_API_URL}/auth/signup`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					credentials: "include",
					body: JSON.stringify({
						email: payload.email,
						walletAddress: payload.wallet,
					}),
				},
			);

			if (!response.ok) {
				throw new Error("Failed to signup");
			}
		},
	});
	const { login } = useLogin({
		onComplete: async (user, isNewUser) => {
			if (!isNewUser) {
				return navigate({
					to: next || "/",
				});
			}

			const token = await getAccessToken();

			if (!token) {
				throw new Error("No access token found");
			}

			if (!user.email?.address) {
				throw new Error("No email found");
			}

			if (!user.wallet?.address) {
				console.warn(
					"[INFO] Make sure you create an embedded wallet upon account creation in Privy",
				);

				throw new Error("No wallet found");
			}

			await signup({
				token,
				payload: {
					email: user.email.address,
					wallet: user.wallet?.address,
				},
			});

			return navigate({
				to: next || "/merchant",
			});
		},
	});
	const { ready } = usePrivy();

	return (
		<div className="h-screen w-full flex items-center justify-center">
			<div className="max-w-xl flex flex-col items-center space-y-16">
				<div className="flex flex-col items-center space-y-6">
					<InflowIcon className="w-40 h-16 fill-black" />
					<button
						type="button"
						disabled={!ready}
						onClick={login}
						className="w-fit px-2 py-1 border border-neutral-200 shadow-sm text-sm rounded-md text-neutral-700 transition-colors duration-200 hover:border-neutral-300 hover:text-neutral-800"
					>
						Login with Privy
					</button>
				</div>
				<span className="text-xs text-neutral-200">
					App version: {import.meta.env.MODE}.{import.meta.env.COMMIT_HASH}
				</span>
			</div>
		</div>
	);
}
