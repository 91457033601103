import { getAccessToken } from "@privy-io/react-auth";
import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { z } from "zod";
import { fetcher } from "../lib/fetcher";
import { externalAccountSchema } from "../schemas/external-account";
import type { QueryOptions } from "./types";

type Options = QueryOptions<Awaited<ReturnType<typeof getExternalAccounts>>>;

async function getExternalAccounts() {
	const token = await getAccessToken();

	if (!token) {
		throw new Error("Unauthorized");
	}

	const data = await fetcher(
		`${import.meta.env.VITE_API_URL}/dashboard/accounts`,
		z.array(externalAccountSchema),
		{
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		},
	);

	return data
		.sort((a, b) => {
			if (a.isArchived !== b.isArchived) {
				return a.isArchived ? 1 : -1;
			}

			return dayjs(a.createdAt).isAfter(dayjs(b.createdAt)) ? 1 : -1;
		})
		.map((account) =>
			account.object === "bank-account"
				? {
						...account,
						iban: `••••${account.iban.slice(-4)}`,
					}
				: {
						...account,
						address: `${account.address.slice(0, 6)}••••${account.address.slice(
							-4,
						)}`,
					},
		);
}

export function externalAccountsOptions(options: Options) {
	return {
		queryKey: ["external-accounts"],
		queryFn: () => getExternalAccounts(),
		staleTime: 10 * 1000,
		...options,
	};
}

export function useExternalAccounts(options: Options = {}) {
	const { data, ...query } = useQuery(externalAccountsOptions(options));

	return {
		externalAccounts: data,
		...query,
	};
}

export function useSuspenseExternalAccounts(options: Options = {}) {
	const { data, ...query } = useSuspenseQuery(externalAccountsOptions(options));

	return {
		externalAccounts: data,
		...query,
	};
}
