import { z } from "zod";

export const externalAccountSchema = z.discriminatedUnion("object", [
	z.object({
		id: z.string(),
		object: z.literal("bank-account"),
		accountOwnerName: z.string(),
		iban: z.string(),
		bic: z.string(),
		isArchived: z.boolean(),
		createdAt: z.coerce.date(),
		updatedAt: z.coerce.date(),
	}),
	z.object({
		id: z.string(),
		object: z.literal("wallet"),
		address: z.string(),
		network: z.enum(["polygon"]),
		isArchived: z.boolean(),
		createdAt: z.coerce.date(),
		updatedAt: z.coerce.date(),
	}),
]);

export type ExternalAccount = z.infer<typeof externalAccountSchema>;
