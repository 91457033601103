import { zodResolver } from "@hookform/resolvers/zod";
import { usePrivy } from "@privy-io/react-auth";
import { Link, createRoute, useNavigate } from "@tanstack/react-router";
import Big from "big.js";
import { useContext, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useExternalAccounts } from "../../api/use-external-accounts";
import { useQuote } from "../../api/use-quote";
import { useWithdraw } from "../../api/use-withdraw";
import { useBalance, useSuspenseBalance } from "../../api/useBalance";
import { WarningIcon } from "../../assets/warning";
import { XMarkIcon } from "../../assets/xmark";
import { RenderAccount } from "../../components/accounts/render-account";
import { Button } from "../../components/ui/button";
import { dashLayout } from "../../layouts/dash";
import { cn } from "../../lib/cn";
import { ModalContext } from "../../providers/modal";
import type { ExternalAccount } from "../../schemas/external-account";
import type { Withdraw } from "../../schemas/withdraw";
import { estimateRenderedTextSize } from "../../utils/estimate-rendered-text-size";

export const dashBalanceRoute = createRoute({
	getParentRoute: () => dashLayout,
	path: "/balance",
	component: BalancePage,
});

function BalancePage() {
	const { externalAccounts, isLoading } = useExternalAccounts({
		retry: false,
	});

	const hasActiveAccounts = useMemo(
		() =>
			(externalAccounts?.filter(({ isArchived }) => !isArchived).length ?? 0) >
			0,
		[externalAccounts],
	);

	return (
		<div className="md:py-6 px-6 md:px-12 w-full flex flex-col space-y-4">
			<h1 className="font-semibold text-2xl">Balances</h1>
			{!hasActiveAccounts && !isLoading && (
				<div className="w-full px-4 py-2 flex items-center justify-between border border-red-300 border-dashed bg-red-50 rounded-md">
					<div className="flex items-center space-x-2">
						<WarningIcon className="w-4" />
						<span className="text-sm">
							Please add or activate a bank account or wallet to withdraw.
						</span>
					</div>
					<Link to="/settings" className="flex-1 text-right text-sm underline">
						Setup now
					</Link>
				</div>
			)}
			<div className="flex flex-col">
				<div className="flex space-x-2 border-b border-neutral-300">
					<Link
						to="/balance"
						activeProps={{
							className: "border-b-2 border-inflow-800",
						}}
						className="pb-2"
						activeOptions={{
							includeSearch: false,
						}}
					>
						{({ isActive }) => (
							<span
								className={cn(
									"px-2 py-1 text-sm text-neutral-700 font-medium rounded-md",
									isActive
										? "text-inflow-800 hover:bg-inflow-300/20"
										: "text-neutral-700 hover:bg-neutral-100",
								)}
							>
								Overview
							</span>
						)}
					</Link>
					<Link
						to="/payouts"
						search={{
							page: 1,
						}}
						activeProps={{
							className: "border-b-2 border-inflow-800",
						}}
						className="pb-2"
						activeOptions={{
							includeSearch: false,
						}}
					>
						{({ isActive }) => (
							<span
								className={cn(
									"px-2 py-1 text-sm font-medium rounded-md",
									isActive
										? "text-inflow-800 hover:bg-inflow-300/20"
										: "text-neutral-700 hover:bg-neutral-100",
								)}
							>
								Payouts
							</span>
						)}
					</Link>
				</div>
				<div className="flex flex-col _border-b _border-neutral-300 divide-y divide-neutral-300">
					<BalanceOverview />
					{/* <IncomingPayouts /> */}
				</div>
			</div>
		</div>
	);
}

function BalanceOverview() {
	const { balance } = useBalance();
	const { externalAccounts, isError } = useExternalAccounts();
	const ctx = useContext(ModalContext);

	const hasActiveAccounts = useMemo(
		() =>
			(externalAccounts?.filter(({ isArchived }) => !isArchived).length ?? 0) >
			0,
		[externalAccounts],
	);

	return (
		<section className="py-6 flex flex-col">
			<div className="flex justify-between items-center">
				<div className="flex flex-col space-y-1">
					<h2 className="font-semibold text-lg">USDC Balance</h2>
					{balance ? (
						<span className="flex space-x-1 text-sm text-neutral-700">
							<span>{balance.currencySymbol}</span>
							<span>{balance.readableAmount}</span>
						</span>
					) : (
						<div className="w-20 h-5 bg-neutral-300 rounded-md animate-pulse" />
					)}
				</div>
				<Button
					intent="outline"
					disabled={
						!hasActiveAccounts ||
						isError ||
						(balance?.readableAmount ?? 0) <= 1.01
					}
					onClick={async () => {
						ctx?.open(<ConfigureAmountModal />);
					}}
				>
					Withdraw
				</Button>
			</div>
		</section>
	);
}

const formValuesSchema = z.object({
	amount: z.string(),
});

type FormValues = z.infer<typeof formValuesSchema>;

function ConfigureAmountModal() {
	const ctx = useContext(ModalContext);
	const { balance } = useSuspenseBalance();
	const {
		handleSubmit,
		register,
		formState: { errors },
		reset,
		setError,
		watch,
		clearErrors,
	} = useForm<FormValues>({
		resolver: zodResolver(formValuesSchema),
		mode: "onSubmit",
		reValidateMode: "onChange",
		defaultValues: {
			amount: "0",
		},
	});

	useEffect(() => {
		if (balance) {
			reset({
				amount: balance.readableAmount.toFixed(2),
			});
		}
	}, [balance, reset]);

	// biome-ignore lint/correctness/useExhaustiveDependencies: Refresh every time amount changes
	useEffect(() => {
		const amount = watch("amount");

		if (amount) {
			const num = Number.parseFloat(amount.replace(/,/g, "."));

			if (num < 1.01) {
				setError("amount", {
					type: "manual",
					message: "Minimum for a withdrawal is $1.01",
				});
			} else if (num > balance?.readableAmount) {
				setError("amount", {
					type: "manual",
					message: "Amount exceeds your balance",
				});
			} else {
				clearErrors("amount");
			}
		} else {
			clearErrors("amount");
		}
	}, [watch, watch("amount"), balance, clearErrors, setError]);

	const renderedAmount = watch("amount")?.length > 0 ? watch("amount") : "0";

	function onSubmit(values: FormValues, e?: React.BaseSyntheticEvent) {
		e?.preventDefault();

		ctx?.open(
			<SelectAccountModal
				amount={Number.parseFloat(values.amount.replace(/,/g, "."))}
			/>,
		);
	}

	return (
		<form
			className="w-full max-w-lg flex flex-col space-y-8 bg-white rounded-md"
			onSubmit={handleSubmit(onSubmit)}
		>
			<div className="pt-4 px-6 flex justify-between items-center">
				<span className="font-medium">Choose your withdraw amount</span>
				<button type="button" onClick={() => ctx?.close()}>
					<XMarkIcon className="w-4 h-4" />
				</button>
			</div>
			<div className="px-6 flex flex-col items-center">
				{errors.amount ? (
					<span className="text-sm text-red-700">{errors.amount.message}</span>
				) : null}
				<div className="flex items-center justify-center">
					<label
						htmlFor="amount"
						className={cn(
							"pl-3 text-xl font-bold",
							errors.amount && "text-red-700",
						)}
					>
						USDC
					</label>
					<input
						id="amount"
						type="text"
						placeholder="0"
						style={
							{
								"--input-width": `${
									estimateRenderedTextSize(renderedAmount, "20px DM Sans") + 24
								}px`, // Caps length at 10ch
							} as React.CSSProperties
						}
						className={cn(
							"px-3 py-1.5 text-xl font-bold outline-none w-[var(--input-width)]",
							errors.amount && "text-red-700",
						)}
						{...register("amount")}
						value={watch("amount")}
						onChange={(e) => {
							const value = e.target.value;

							// check for invalid characters
							if (!/^\d*[\.,]?\d*$/.test(value)) {
								return;
							}

							// check for a dot and a comma
							if (value.indexOf(".") > -1 && value.indexOf(",") > -1) {
								return;
							}

							// check for multiple dots or commas
							if (value.split(".").length > 2 || value.split(",").length > 2) {
								return;
							}

							// limit to two decimal points after a `.`
							if (
								value.indexOf(".") > -1 &&
								value.split(".").length === 2 &&
								value.split(".")[1].length > 2
							) {
								return;
							}

							// limit to two decimal points after a `,`
							if (
								value.indexOf(",") > -1 &&
								value.split(",").length === 2 &&
								value.split(",")[1].length > 2
							) {
								return;
							}

							register("amount").onChange(e);
						}}
					/>
				</div>
				<div className="mx-auto px-3 py-2 bg-neutral-100 rounded-full flex items-center justify-center">
					<span className="text-xs font-medium text-neutral-600">
						Available balance: {balance?.currencySymbol}{" "}
						{balance?.readableAmount.toFixed(2)}
					</span>
				</div>
			</div>
			<div className="pb-4 px-6 flex space-x-4 justify-end items-center">
				<Button onClick={() => ctx?.close()}>Cancel</Button>
				<Button
					disabled={!!errors.amount}
					type="submit"
					color="inflow"
					intent="solid"
				>
					Continue
				</Button>
			</div>
		</form>
	);
}

type SelectAccountModalProps = {
	amount: number;
};

function SelectAccountModal({ amount }: SelectAccountModalProps) {
	const ctx = useContext(ModalContext);
	const { externalAccounts } = useExternalAccounts();

	return (
		<div className="w-full max-w-lg flex flex-col space-y-4 bg-white rounded-md">
			<div className="pt-4 px-6 flex justify-between items-center">
				<span className="font-medium">Select your account</span>
				<button type="button" onClick={() => ctx?.close()}>
					<XMarkIcon className="w-4 h-4" />
				</button>
			</div>
			<span className="px-6 text-sm text-neutral-700">
				Select the account you wish to withdraw to.
			</span>
			<div className="flex flex-col border-y border-neutral-200 bg-neural-100 divide-y divide-neutral-200">
				{externalAccounts
					?.filter(({ isArchived }) => !isArchived)
					.map((account) => (
						<button
							key={account.id}
							type="button"
							onClick={() => {
								ctx?.open(
									<ConfirmWithdrawModal account={account} amount={amount} />,
								);
							}}
						>
							<RenderAccount
								account={account}
								className="px-6 py-4 hover:bg-neutral-100 transition-colors duration-200"
							/>
						</button>
					))}
			</div>
			<div className="pb-4 px-6 flex space-x-4 justify-between items-center">
				<Button onClick={() => ctx?.open(<ConfigureAmountModal />)}>
					Back
				</Button>
				<Button onClick={() => ctx?.close()}>Cancel</Button>
			</div>
		</div>
	);
}

type ConfirmWithdrawModalProps = {
	account: ExternalAccount;
	amount: number;
};

function ConfirmWithdrawModal({ account, amount }: ConfirmWithdrawModalProps) {
	const ctx = useContext(ModalContext);
	const { authenticated, ready, login } = usePrivy();
	const { quote } = useQuote({
		enabled: account.object === "bank-account",
	});
	const { mutate, isPending, isError } = useWithdraw({
		onSuccess: (data) => {
			ctx?.open(
				<WithdrawalConfirmationModal account={account} withdraw={data} />,
			);
		},
	});

	return (
		<div className="w-full max-w-lg flex flex-col space-y-4 bg-white rounded-md">
			<div className="pt-4 px-6 flex justify-between items-center">
				<span className="font-medium">Confirm withdrawal</span>
				<button disabled={isPending} type="button" onClick={() => ctx?.close()}>
					<XMarkIcon className="w-4 h-4" />
				</button>
			</div>
			{isError && (
				<div className="px-6 py-4 flex items-center space-x-4 bg-red-100 border-y border-red-200">
					<span className="text-sm text-red-700">
						An error occurred while withdrawing your funds. If the problem
						persists, please contact support.
					</span>
				</div>
			)}
			{account.object === "bank-account" && quote ? (
				<span className="px-6 text-sm text-neutral-700">
					You are about to withdraw {amount.toFixed(2)} USDC (
					<span className="font-medium">
						~€ {(quote?.buy_rate * amount).toFixed(2)}
					</span>
					) to the account below.
				</span>
			) : (
				<span className="px-6 text-sm text-neutral-700">
					You are about to withdraw {amount.toFixed(2)} USDC to the account
					below.
				</span>
			)}
			<RenderAccount
				account={account}
				className="px-6 py-4 flex items-center space-x-4 bg-neutral-100 border-y border-neutral-200"
			/>
			<div className="pb-4 px-6 flex space-x-4 justify-between items-center">
				<Button
					disabled={isPending}
					onClick={() => ctx?.open(<SelectAccountModal amount={amount} />)}
				>
					Back
				</Button>
				<div className="flex space-x-4 items-center">
					<Button disabled={isPending} onClick={() => ctx?.close()}>
						Cancel
					</Button>
					<Button
						color="inflow"
						intent="solid"
						disabled={!ready || isError || isPending}
						onClick={async () => {
							if (!authenticated) {
								login();
							} else {
								mutate({
									id: account.id,
									amount: new Big(amount).mul(100).toNumber(),
								});
							}
						}}
					>
						{isPending
							? "Loading..."
							: ready && authenticated
								? "Confirm"
								: "Verify with code"}
					</Button>
				</div>
			</div>
		</div>
	);
}

type WithdrawalConfirmationModalProps = {
	account: ExternalAccount;
	withdraw: Withdraw;
};

function WithdrawalConfirmationModal({
	account,
	withdraw,
}: WithdrawalConfirmationModalProps) {
	const ctx = useContext(ModalContext);
	const navigate = useNavigate({
		from: "/balance",
	});

	return (
		<div className="w-full max-w-lg flex flex-col space-y-4 bg-white rounded-md">
			<div className="pt-4 px-6 flex justify-between items-center">
				<span className="font-medium">Withdrawal confirmation</span>
				<button type="button" onClick={() => ctx?.close()}>
					<XMarkIcon className="w-4 h-4" />
				</button>
			</div>
			{account.object === "bank-account" ? (
				<p className="px-6 text-sm text-neutral-700">
					Your withdrawal request has been successfully requested. Your funds
					will be transferred to the account below. Please allow for up to 3
					business days for your funds to arrive.
				</p>
			) : (
				<p className="px-6 text-sm text-neutral-700">
					Your withdrawal request has been successfully requested. Your funds
					will be transferred to the wallet below within a few minutes.
				</p>
			)}
			<RenderAccount
				account={account}
				className="px-6 py-4 flex items-center space-x-4 bg-neutral-100 border-y border-neutral-200"
			/>
			<div className="pb-4 px-6 flex space-x-4 justify-end items-center">
				<Button onClick={() => ctx?.close()}>Close</Button>
				<Button
					color="inflow"
					intent="solid"
					onClick={() => {
						ctx?.close();
						navigate({
							to: "/payout/$id",
							params: {
								id: withdraw.id,
							},
						});
					}}
				>
					See details
				</Button>
			</div>
		</div>
	);
}
