import { getAccessToken, useSignTypedData } from "@privy-io/react-auth";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { z } from "zod";
import { fetcher } from "../lib/fetcher";
import { eip2612Schema } from "../schemas/eip2612";
import { type Withdraw, withdrawSchema } from "../schemas/withdraw";

type Props = {
	onSuccess: (data: Withdraw) => void;
};

export function useWithdraw({ onSuccess }: Props) {
	// We can't use the pending from the muation because we need to show the
	// loading state of the signTypedData as well
	const [isPending, setIsPending] = useState(false);
	const [isSignTypedDataError, setIsSignTypedDataError] = useState(false);
	const [withdrawalId, setWithdrawalId] = useState<string | null>(null);

	const { mutate: cancelWithdraw, isError: isCancelWithdrawError } =
		useMutation({
			mutationKey: ["cancel-withdraw"],
			mutationFn: async () => {
				const token = await getAccessToken();

				if (!token) {
					throw new Error("Unauthorized");
				}

				return fetcher(
					`${
						import.meta.env.VITE_API_URL
					}/dashboard/withdraw/cancel/${withdrawalId}`,
					z.any(),
					{
						method: "DELETE",
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${await getAccessToken()}`,
						},
					},
				);
			},
			onSuccess: () => {
				setIsPending(false);
			},
			onError: () => {
				setIsPending(false);
			},
		});

	const { signTypedData } = useSignTypedData({
		onSuccess: (sig) => {
			submitWithdraw(sig);
		},
		onError: () => {
			cancelWithdraw();
			setIsSignTypedDataError(true);
			setIsPending(false);
		},
	});

	const { mutate, isError: isRequestWithdrawError } = useMutation({
		mutationKey: ["request-withdraw"],
		mutationFn: async ({ id, amount }: { id: string; amount: number }) => {
			setIsPending(true);

			const token = await getAccessToken();

			if (!token) {
				throw new Error("Unauthorized");
			}

			return fetcher(
				`${import.meta.env.VITE_API_URL}/dashboard/withdraw/request/${id}`,
				z.object({
					withdrawalId: z.string(),
					typedData: eip2612Schema,
				}),
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${await getAccessToken()}`,
					},
					body: JSON.stringify({ amountInCents: amount }),
				},
			);
		},
		onSuccess: ({ withdrawalId, typedData }) => {
			setWithdrawalId(withdrawalId);

			// @ts-ignore
			signTypedData(typedData);
		},
		onError: () => {
			setIsPending(false);
		},
	});

	const { mutate: submitWithdraw, isError: isSubmitWithdrawError } =
		useMutation({
			mutationKey: ["submit-withdraw"],
			mutationFn: async (signature: string) => {
				const token = await getAccessToken();

				if (!token) {
					throw new Error("Unauthorized");
				}

				return fetcher(
					`${
						import.meta.env.VITE_API_URL
					}/dashboard/withdraw/submit/${withdrawalId}`,
					withdrawSchema,
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${await getAccessToken()}`,
						},
						body: JSON.stringify({ signature }),
					},
				);
			},
			onSuccess: (data) => {
				setIsPending(false);
				onSuccess(data);
			},
			onError: () => {
				cancelWithdraw();
			},
		});

	return {
		mutate,
		isPending,
		isError:
			isRequestWithdrawError ||
			isSubmitWithdrawError ||
			isCancelWithdrawError ||
			isSignTypedDataError,
	};
}
