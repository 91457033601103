import { zodResolver } from "@hookform/resolvers/zod";
import { getAccessToken } from "@privy-io/react-auth";
import { useMutation } from "@tanstack/react-query";
import { Link, useNavigate, useSearch } from "@tanstack/react-router";
import Big from "big.js";
import { useMemo } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { z } from "zod";
import { LinkIcon } from "../../assets/link";
import { PlusIcon } from "../../assets/plus";
import { fetcher } from "../../lib/fetcher";
import { numberFormatter } from "../../lib/number";
import { Button } from "../ui/button";

const formValuesSchema = z.object({
	products: z
		.array(
			z.object({
				description: z.string(),
				amount: z.number(),
				quantity: z.number(),
			}),
		)
		.min(1, "You need at least one product"),
});

type FormValues = z.infer<typeof formValuesSchema>;

export function CreatePaymentLink() {
	const search = useSearch({
		from: "/empty/links/new",
	});
	const navigate = useNavigate({
		from: "/links/new",
	});
	const {
		handleSubmit,
		control,
		formState: { errors },
		watch,
	} = useForm<FormValues>({
		resolver: zodResolver(formValuesSchema),
		mode: "onSubmit",
		reValidateMode: "onChange",
		defaultValues: search,
	});
	const { fields } = useFieldArray({
		control,
		name: "products",
	});

	const { mutate, isPending } = useMutation({
		mutationKey: ["payment-link"],
		mutationFn: async (data: FormValues) => {
			return fetcher(
				`${import.meta.env.VITE_API_URL}/dashboard/links`,
				z.object({
					id: z.string(),
					amount: z.number(),
					currency: z.string(),
					status: z.enum(["enabled", "disabled"]),
					userId: z.string(),
					createdAt: z.coerce.date(),
					updatedAt: z.coerce.date(),
				}),
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${await getAccessToken()}`,
					},
					body: JSON.stringify({
						products: data.products.map((product) => ({
							name: product.description,
							price: new Big(product.amount).mul(100).toNumber(),
							quantity: product.quantity,
						})),
						currency: "EUR",
					}),
				},
			);
		},
		onSuccess: ({ id }) => {
			navigate({
				to: "/link/$id",
				params: {
					id,
				},
			});
		},
	});

	const amount = useMemo(() => {
		return fields
			.flatMap((product) => product.amount * product.quantity)
			.reduce((acc, price) => acc + price, 0);
	}, [fields]);

	async function onSubmit(data: FormValues) {
		if (amount < 1.01) {
			return;
		}

		mutate(data);
	}

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			className="w-full flex flex-col space-y-6 max-w-3xl mx-auto"
		>
			<div className="flex flex-col space-y-2">
				<div className="flex flex-col">
					<span className="text-neutral-700">Products</span>
					{errors.products && (
						<span className="text-sm text-red-500">
							{errors.products.message}
						</span>
					)}
				</div>
				<div className="flex flex-col space-y-2 max-h-96 overflow-y-auto">
					{fields.map(({ id, ...field }) => (
						<Link
							key={id}
							to="/products/add"
							search={{
								...search,
								edit: JSON.stringify(field),
							}}
							className="w-full px-3 py-1.5 flex justify-between items-start border border-neutral-200 bg-neutral-50 rounded-lg cursor-pointer"
						>
							<div className="flex flex-col">
								<span className="text-sm text-neutral-700">
									{field.description}
								</span>
								<span className="text-sm text-neutral-700">
									Quantity: {field.quantity}
								</span>
							</div>
							<div className="flex flex-col items-end">
								<span className="text-sm text-neutral-700">
									{numberFormatter(field.amount * field.quantity, {
										style: "currency",
										currency: "EUR",
									})}
								</span>
								{field.quantity > 1 && (
									<span className="text-sm text-neutral-700">
										{numberFormatter(field.amount, {
											style: "currency",
											currency: "EUR",
										})}
										/each
									</span>
								)}
							</div>
						</Link>
					))}
				</div>
				<Link className="w-full" to="/products/add" search={watch()}>
					<Button
						intent="outline"
						color="inflow"
						className="w-full py-2"
						icon={<PlusIcon className="w-4 h-4 fill-inflow-700" />}
						iconPosition="left"
					>
						{fields.length === 0 ? "Add a product" : "Add another product"}
					</Button>
				</Link>
			</div>
			<div className="w-full px-3 py-2 flex flex-col space-y-1 border border-neutral-200 rounded-lg">
				<div className="flex items-center justify-between">
					<span className="text-sm text-neutral-700">Currency</span>
					<span className="text-sm text-inflow-700">EUR • Euro</span>
				</div>
				<div className="flex items-center justify-between">
					<span className="text-sm text-neutral-700">Subtotal</span>
					<span className="text-sm text-inflow-700">
						{numberFormatter(amount, {
							currency: "EUR",
							style: "currency",
						})}
					</span>
				</div>
				<div className="flex items-center justify-between">
					<span className="text-sm text-neutral-700">Total</span>
					<span className="text-sm text-inflow-700">
						{numberFormatter(amount, {
							currency: "EUR",
							style: "currency",
						})}
					</span>
				</div>
			</div>
			<div className="flex flex-col space-y-2 items-center">
				<Button
					type="submit"
					intent="solid"
					color="inflow"
					className="w-full py-2"
					icon={<LinkIcon className="w-4 h-4 fill-white" />}
					iconPosition="left"
					disabled={watch("products")?.length === 0 || amount < 1.01}
					isLoading={isPending}
				>
					Create link
				</Button>
				{amount < 1.01 ? (
					<span className="text-sm text-red-400">
						You need a minumum amount of 1.01€ to create a payment link
					</span>
				) : null}
			</div>
		</form>
	);
}
