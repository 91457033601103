import { Link } from "@tanstack/react-router";
import { useDebounce } from "@uidotdev/usehooks";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSearch } from "../../api/use-search";
import { SearchIcon } from "../../assets/search";
import { cn } from "../../lib/cn";
import { Loading } from "../loading";

export function Search() {
	const ref = useRef<HTMLInputElement>(null);
	const [isVisible, setIsVisible] = useState(false);
	const [term, setTerm] = useState("");
	const debouncedSearchTerm = useDebounce(term, 300);
	const { search, isLoading } = useSearch(debouncedSearchTerm, {
		enabled: debouncedSearchTerm.length > 0,
	});

	const focusSearch = useCallback((e: KeyboardEvent) => {
		if (e.key === "/") {
			e.preventDefault();
			ref.current?.focus();
		} else if (e.key === "Escape") {
			e.preventDefault();
			ref.current?.blur();
			setIsVisible(false);
		}
	}, []);

	useEffect(() => {
		document.addEventListener("keydown", focusSearch);

		return () => document.removeEventListener("keydown", focusSearch);
	}, [focusSearch]);

	useEffect(() => {
		if (debouncedSearchTerm.length > 0) {
			setIsVisible(true);
		} else {
			setIsVisible(false);
		}
	}, [debouncedSearchTerm]);

	return (
		<div className="md:-ml-3 px-3 py-2 md:relative w-full max-w-md flex items-center space-x-2 group has-[:focus]:bg-neutral-100 hover:bg-neutral-100 transition-all duration-200 rounded-lg">
			<SearchIcon className="mb-px w-3 h-3 fill-neutral-500" />
			<input
				ref={ref}
				type="text"
				placeholder="Search..."
				className="w-full px-1 text-sm placeholder-neutral-500 text-neutral-600 bg-transparent focus:ring-0 outline-none"
				value={term}
				onChange={(e) => {
					setTerm(e.target.value);
				}}
			/>
			{isVisible ? (
				<div
					className={cn(
						"absolute -left-2 top-16 md:top-12 flex-col hidden w-screen md:w-full lg:w-[40rem] -mr-8 border-y md:border-x border-neutral-200 bg-white md:rounded-lg shadow-sm",
						isVisible ? "flex" : "group-has-[:focus]:flex",
					)}
				>
					<span className="px-3 py-2 text-sm text-neutral-500 border-b border-neutral-200">
						Showing all results for{" "}
						<span className="font-medium">{debouncedSearchTerm}</span>
					</span>
					{isLoading ? (
						<Loading className="py-4" />
					) : (
						<ul className="px-1.5 py-1 flex flex-col divide-y divide-neutral-200 divide-dashed z-50">
							{search?.map((object) => (
								<li key={object.id} className="py-1.5 first:pt-0.5 last:pb-0.5">
									<Link
										to={object.to}
										params={{ id: object.id }}
										className="py-1 px-1.5 flex flex-col hover:bg-neutral-100 rounded-md relative z-[999] pointer-events-auto"
										data-search-result={true}
										onClick={() => {
											setIsVisible(false);
											setTerm("");
											ref.current?.blur();
										}}
									>
										<span className="uppercase text-xs text-neutral-500 font-medium">
											{object.object}
										</span>
										<code className="text-sm text-inflow-700">{object.id}</code>
									</Link>
								</li>
							))}
						</ul>
					)}
				</div>
			) : null}
		</div>
	);
}
