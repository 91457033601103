import {
	createRoute,
	useNavigate,
	useRouter,
	useSearch,
} from "@tanstack/react-router";
import { z } from "zod";
import { XMarkIcon } from "../../../assets/xmark";
import { CreatePaymentLink } from "../../../components/links/create-payment-link";
import { emptyLayout } from "../../../layouts/empty";

const searchSchema = z.object({
	products: z
		.array(
			z.object({
				description: z.string(),
				amount: z.number(),
				quantity: z.number().default(1),
			}),
		)
		.catch([]),
	back: z.string().optional(),
});

export const dashPaymentLinkRoute = createRoute({
	getParentRoute: () => emptyLayout,
	path: "/links/new",
	component: PaymentLinkPage,
	validateSearch: searchSchema.parse,
});

function PaymentLinkPage() {
	const { history } = useRouter();
	const { back } = useSearch({
		from: "/empty/links/new",
	});
	const navigate = useNavigate({
		from: "/links/new",
	});

	return (
		<div className="py-4 md:py-6 px-4 md:px-8 w-full flex flex-col space-y-6">
			<div className="flex items-center justify-between">
				<div className="flex items-center space-x-4">
					<button
						type="button"
						onClick={() => {
							if (back) {
								navigate({
									to: "/",
								});
							} else {
								history.go(-1);
							}
						}}
					>
						<XMarkIcon className="w-4 h-4 fill-neutral-600" />
					</button>
					<div className="h-4 w-px bg-neutral-200" />
					<h1 className="text-neutral-600 font-medium">
						Create a payment link
					</h1>
				</div>
			</div>
			<CreatePaymentLink />
		</div>
	);
}
