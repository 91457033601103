import { cn } from "../../lib/cn";

type Props = {
	customLabel?: string;
	className?: string;
};

export function NoData({ customLabel, className }: Props) {
	return (
		<div
			className={cn(
				"py-2 px-3 flex flex-col items-center justify-center border-2 border-dashed border-neutral-300 rounded-lg",
				className,
			)}
		>
			<span className="font-medium text-sm text-neutral-600">
				{customLabel || "No data available"}
			</span>
		</div>
	);
}
