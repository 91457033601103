import { Link, Outlet, createRoute, redirect } from "@tanstack/react-router";
import { useClickAway } from "@uidotdev/usehooks";
import { useState } from "react";
import { BarsIcon } from "../assets/bars";
import { CodeIcon } from "../assets/code";
import { HomeIcon } from "../assets/home";
import { InflowIcon } from "../assets/inflow";
import { LinkIcon } from "../assets/link";
import { MoneyChartIcon } from "../assets/money-chart";
import { QuestionIcon } from "../assets/question";
import { SettingsIcon } from "../assets/settings";
import { SlantedBarsIcon } from "../assets/slanted-bars";
import { XMarkIcon } from "../assets/xmark";
import { Search } from "../components/dash/search";
import { NotFound } from "../components/not-found";
import { Button } from "../components/ui/button";
import { cn } from "../lib/cn";
import { getUser } from "../lib/get-user";
import { rootRoute } from "./_root";

export const dashLayout = createRoute({
	id: "dash",
	getParentRoute: () => rootRoute,
	component: DashLayout,
	loader: async ({ context }) => {
		const user = await getUser(context.accessToken);

		if (!user) {
			throw redirect({
				to: "/login",
				search: {
					next: context.location.href,
				},
			});
		}

		if (user?.legalName === null) {
			throw redirect({
				to: "/merchant",
				search: {
					next: context.location.href,
				},
			});
		}

		return {
			user,
		};
	},
	notFoundComponent: () => <NotFound className="h-[calc(100vh_-_60px)]" />,
});

function DashLayout() {
	const [open, setOpen] = useState(false);
	const ref = useClickAway<HTMLDivElement>(() => {
		setOpen(false);
	});

	return (
		<div className="h-screen flex flex-row relative">
			<div className="w-64 flex-shrink-0 hidden md:block" />
			<div
				ref={ref}
				className={cn(
					"w-3/4 md:w-64 py-4 px-6 md:p-6 h-screen flex flex-col space-y-12 z-50",
					"bg-white border-r border-neutral-200",
					"fixed top-0 left-0 transform transition-all duration-300 ease-in-out",
					open ? "translate-x-0" : "-translate-x-full md:translate-x-0",
				)}
			>
				<div className="flex items-center justify-between">
					<Link to="/">
						<InflowIcon className="w-12 h-6 fill-black" />
					</Link>
					<button
						type="button"
						onClick={() => {
							setOpen(false);
						}}
						className="p-2 md:hidden"
					>
						<XMarkIcon className="w-4 h-4 fill-neutral-600" />
					</button>
				</div>
				<div className="flex flex-col space-y-4">
					<button onClick={() => setOpen(false)} type="button">
						<Link to="/" className="w-full flex items-center space-x-2">
							<HomeIcon className="w-5 h-5 fill-neutral-700" />
							<span className="text-sm font-medium text-neutral-700">Home</span>
						</Link>
					</button>
					<button onClick={() => setOpen(false)} type="button">
						<Link
							to="/payments"
							search={{
								page: 1,
							}}
							className="w-full flex items-center space-x-2"
						>
							<SlantedBarsIcon className="w-5 h-4 fill-neutral-700" />
							<span className="text-sm font-medium text-neutral-700">
								Payments
							</span>
						</Link>
					</button>
					<button onClick={() => setOpen(false)} type="button">
						<Link
							to="/links"
							search={{
								page: 1,
							}}
							className="w-full flex items-center space-x-2"
						>
							<LinkIcon className="w-5 h-4 fill-neutral-700" />
							<span className="text-sm font-medium text-neutral-700">
								Links
							</span>
						</Link>
					</button>
					<button onClick={() => setOpen(false)} type="button">
						<Link to="/balance" className="w-full flex items-center space-x-2">
							<MoneyChartIcon className="w-5 h-4 fill-neutral-700" />
							<span className="text-sm font-medium text-neutral-700">
								Balance
							</span>
						</Link>
					</button>
					<button onClick={() => setOpen(false)} type="button">
						<Link
							to="/developers"
							className="w-full flex items-center space-x-2"
						>
							<CodeIcon className="w-5 h-4 fill-neutral-700" />
							<span className="text-sm font-medium text-neutral-700">
								Developers
							</span>
						</Link>
					</button>
					<Link
						to="/links/new"
						search={{
							products: [],
						}}
						className="md:hidden"
					>
						<Button intent="solid" color="inflow" className="w-full text-left">
							Create payment link
						</Button>
					</Link>
				</div>
			</div>
			<div className="w-full flex flex-col">
				<header className="w-full sticky top-0 left-0 py-4 md:py-6 px-6 md:px-12 flex justify-between items-center space-x-4 md:space-x-0 backdrop-blur-sm z-40">
					<button
						type="button"
						className="flex md:hidden"
						onClick={() => setOpen((prev) => !prev)}
					>
						<BarsIcon className="mb-px w-4 h-4 fill-neutral-700" />
					</button>
					<Search />
					<div className="flex items-center space-x-6">
						<a
							href="https://inflow-pay.readme.io/reference/introduction?ref=merchant"
							target="_blank"
							rel="noreferrer"
						>
							<QuestionIcon className="w-4 h-4 fill-neutral-700 overflow-visible" />
						</a>
						<Link to="/settings">
							<SettingsIcon className="w-4 h-4 fill-neutral-700 overflow-visible" />
						</Link>
					</div>
				</header>
				<main className="pb-12">
					<Outlet />
				</main>
			</div>
		</div>
	);
}
