export function estimateRenderedTextSize(text: string, font = "16px Arial") {
	const canvas = document.createElement("canvas");
	const context = canvas.getContext("2d");

	if (!context) {
		throw new Error("Could not get 2d context from canvas");
	}

	context.font = font;

	return context.measureText(text).width + text.length;
}
