import { Menu, Transition } from "@headlessui/react";
import { Fragment, useContext, useMemo } from "react";
import { useDeleteExternalAccount } from "../../api/mutations/use-delete-external-account";
import { useExternalAccounts } from "../../api/use-external-accounts";
import { TrashIcon } from "../../assets/trash";
import { ModalContext } from "../../providers/modal";
import { RenderAccount } from "../accounts/render-account";
import { NoData } from "../charts/no-data";
import { Button } from "../ui/button";
import { AddBankAccountModal } from "./add-bank-account-modal";
import { AddWalletModal } from "./add-wallet-modal";
import { ConfirmDeleteModal } from "./confirm-delete-modal";

export function ManageExternalAccounts() {
	const ctx = useContext(ModalContext);
	const { externalAccounts } = useExternalAccounts();
	const { mutate } = useDeleteExternalAccount();

	const hasReachedMaxAccounts = useMemo(
		() => (externalAccounts?.length ?? 0) >= 3,
		[externalAccounts],
	);

	return (
		<div className="p-4 w-full flex flex-col space-y-4">
			<div className="flex items-center justify-between">
				<p className="font-medium">Accounts</p>
				<Menu as="div" className="relative">
					<Menu.Button as="div">
						<Button
							disabled={hasReachedMaxAccounts}
							color="inflow"
							intent="solid"
						>
							Add
						</Button>
					</Menu.Button>
					<Transition
						as={Fragment}
						enter="transition duration-100 ease-out"
						enterFrom="transform scale-95 opacity-0"
						enterTo="transform scale-100 opacity-100"
						leave="transition duration-75 ease-out"
						leaveFrom="transform scale-100 opacity-100"
						leaveTo="transform scale-95 opacity-0"
					>
						<Menu.Items className="absolute max-h-72 overflow-auto right-0 z-30 mt-2 flex w-48 origin-top-right flex-col divide-y divide-neutral-200 rounded-md border border-y border-neutral-200 bg-white shadow-sm">
							<div className="flex w-full flex-col space-y-1 px-1 py-1">
								<Menu.Item
									as="button"
									className="rounded-md hover:bg-neutral-100 focus:bg-neutral-100"
									onClick={() => ctx?.open(<AddBankAccountModal />)}
								>
									<span className="block px-2 py-1 text-left font-medium text-sm text-neutral-700">
										Add bank account
									</span>
								</Menu.Item>
								<Menu.Item
									as="button"
									className="rounded-md hover:bg-neutral-100 focus:bg-neutral-100"
									onClick={() => ctx?.open(<AddWalletModal />)}
								>
									<span className="block px-2 py-1 text-left font-medium text-sm text-neutral-700">
										Add crypto wallet
									</span>
								</Menu.Item>
							</div>
						</Menu.Items>
					</Transition>
				</Menu>
			</div>
			<div className="flex flex-col divide-y divide-neutral-200">
				{externalAccounts ? (
					externalAccounts.map((account) => (
						<RenderAccount
							key={account.id}
							account={account}
							action={
								account.isArchived ? null : (
									<button
										type="button"
										className="ml-auto p-2"
										onClick={() =>
											ctx?.open(
												<ConfirmDeleteModal
													account={account}
													onConfirm={() => {
														mutate(account.id);
														ctx?.close();
													}}
												/>,
											)
										}
									>
										<TrashIcon className="w-4 h-4 fill-red-600" />
									</button>
								)
							}
							className="first:pt-0 last:pb-0"
						/>
					))
				) : (
					<NoData customLabel="Add an account to withdraw your funds" />
				)}
			</div>
		</div>
	);
}
