import { getAccessToken } from "@privy-io/react-auth";
import {
	queryOptions,
	useQuery,
	useSuspenseQuery,
} from "@tanstack/react-query";
import { z } from "zod";
import { fetcher } from "../lib/fetcher";
import { linkSchema } from "../schemas/link";
import { type Pagination, paginationSchema } from "../schemas/pagination";

type Filters = {
	page?: number;
	limit?: number;
};

async function getLinks(filters: Filters) {
	const token = await getAccessToken();

	if (!token) {
		throw new Error("Unauthorized");
	}

	const searchParams = new URLSearchParams();

	searchParams.set("page", filters.page?.toString() ?? "1");
	searchParams.set("limit", filters.limit?.toString() ?? "15");

	return fetcher(
		`${import.meta.env.VITE_API_URL}/dashboard/links?${searchParams}`,
		z.object({
			data: linkSchema.array(),
			meta: paginationSchema,
		}),
		{
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		},
	);
}

export function linksOptions(filters: Filters) {
	return queryOptions({
		queryKey: ["links", filters.page, filters.limit],
		queryFn: () => getLinks(filters),
		staleTime: 5 * 1000,
	});
}

export function useLinks(filters: Filters) {
	const { data, ...query } = useQuery(linksOptions(filters));

	return {
		links: data?.data || [],
		pagination: data?.meta || ({} as Pagination),
		...query,
	};
}

export function useSuspenseLinks(filters: Filters) {
	const { data, ...query } = useSuspenseQuery(linksOptions(filters));

	return {
		links: data?.data || [],
		pagination: data?.meta || ({} as Pagination),
		...query,
	};
}
