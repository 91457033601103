import { getAccessToken } from "@privy-io/react-auth";
import { queryOptions, useQuery } from "@tanstack/react-query";
import { fetcher } from "../lib/fetcher";
import { detailedPaymentSchema } from "../schemas/datailed-payment";
import type { PaymentStatus } from "../schemas/payment";

const statusLabels: Record<PaymentStatus, string> = {
	INCOMPLETE: "Checkout session initiated",
	AUTHORIZED: "The payment has been authorized",
	SETTLED: "Payment settled",
	EXPIRED: "Checkout session expired",
	FAILED: "Payment failed",
	REFUNDED: "Payment refunded",
	CANCELED: "Payment canceled",
};

async function getPayment(id: string) {
	const token = await getAccessToken();

	if (!token) {
		throw new Error("Unauthorized");
	}

	const payment = await fetcher(
		`${import.meta.env.VITE_API_URL}/dashboard/payments/${id}`,
		detailedPaymentSchema,
		{
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		},
	);

	const initiatedAt = payment.timeline.find((t) => t.status === "INCOMPLETE");
	const settledAt = payment.timeline.find((t) => t.status === "SETTLED");

	return {
		...payment,
		initiatedAt: initiatedAt ? new Date(initiatedAt.timestamp) : null,
		settledAt: settledAt ? new Date(settledAt.timestamp) : null,
		timeline: payment.timeline
			.map((t) => ({
				...t,
				label: statusLabels[t.status],
			}))
			.reverse(),
	};
}

export function paymentOptions(id: string) {
	return queryOptions({
		queryKey: ["payment", id],
		queryFn: () => getPayment(id),
		staleTime: 5 * 1000,
	});
}

export function usePayment(id: string) {
	const { data, ...query } = useQuery(paymentOptions(id));

	return {
		payment: data,
		...query,
	};
}
