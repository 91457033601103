import type { SVGProps } from "react";

export function QRIcon(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 448 512"
			aria-label="QR icon"
			{...props}
		>
			<title>QR icon</title>
			<path d="M144 80v96H48V80h96zM48 32C21.5 32 0 53.5 0 80v96c0 26.5 21.5 48 48 48h96c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48H48zm96 304v96H48V336h96zM48 288c-26.5 0-48 21.5-48 48v96c0 26.5 21.5 48 48 48h96c26.5 0 48-21.5 48-48V336c0-26.5-21.5-48-48-48H48zM304 80h96v96H304V80zm-48 0v96c0 26.5 21.5 48 48 48h96c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48H304c-26.5 0-48 21.5-48 48zM72 120v16c0 8.8 7.2 16 16 16h16c8.8 0 16-7.2 16-16V120c0-8.8-7.2-16-16-16H88c-8.8 0-16 7.2-16 16zM88 360c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16h16c8.8 0 16-7.2 16-16V376c0-8.8-7.2-16-16-16H88zM328 120v16c0 8.8 7.2 16 16 16h16c8.8 0 16-7.2 16-16V120c0-8.8-7.2-16-16-16H344c-8.8 0-16 7.2-16 16zM256 304V464c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8 7.2-16 16-16s16 7.2 16 16s7.2 16 16 16h64c8.8 0 16-7.2 16-16V304c0-8.8-7.2-16-16-16s-16 7.2-16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16s-7.2-16-16-16H272c-8.8 0-16 7.2-16 16zM368 448a16 16 0 1 0 0 32 16 16 0 1 0 0-32zm64 0a16 16 0 1 0 0 32 16 16 0 1 0 0-32z" />
		</svg>
	);
}
