import { type Table as TableData, flexRender } from "@tanstack/react-table";
import {
	type ForwardedRef,
	type InputHTMLAttributes,
	createContext,
	forwardRef,
	useContext,
} from "react";
// import { IconSearch } from "@/assets/svgs/icons/search";
import { cn } from "../../lib/cn";

type TableContextProps = {
	// biome-ignore lint/suspicious/noExplicitAny: we can accept any table
	table: TableData<any> | null;
};

const TableContext = createContext<TableContextProps>({
	table: null,
});

type TableProps = {
	// biome-ignore lint/suspicious/noExplicitAny: we can accept any table
	table: TableData<any>;
	className?: string;
	children?: React.ReactNode;
};

export function Table({ table, className, children }: TableProps) {
	return (
		<TableContext.Provider value={{ table }}>
			<div
				className={cn(
					"flex w-full flex-col border-y border-neutral-300",
					className,
				)}
			>
				{children}
			</div>
		</TableContext.Provider>
	);
}

function SearchBar(
	{ className, ...props }: InputHTMLAttributes<HTMLInputElement>,
	ref: ForwardedRef<HTMLInputElement>,
) {
	const { table } = useContext(TableContext);

	if (!table) {
		return null;
	}

	return (
		<div className="flex items-center space-x-4 px-6 py-4">
			{/* <IconSearch className="text-neutral-600" /> */}
			<input
				ref={ref}
				{...props}
				className={cn(
					"w-full border-none bg-transparent text-sm text-neutral-600 focus:outline-none",
					className,
				)}
			/>
		</div>
	);
}

Table.SearchBar = forwardRef(SearchBar);

type HeaderProps = {
	className?: string;
};

Table.Header = function Header({ className }: HeaderProps) {
	const { table } = useContext(TableContext);

	if (!table) {
		return null;
	}

	return (
		<thead className={cn("border-b border-neutral-200", className)}>
			{table.getHeaderGroups().map((headerGroup) => (
				<tr key={headerGroup.id}>
					{headerGroup.headers.map((header) => (
						<th
							key={header.id}
							className="text-left py-2 font-bold text-xs text-neutral-600 max-w-[12rem]"
						>
							{header.isPlaceholder
								? null
								: flexRender(
										header.column.columnDef.header,
										header.getContext(),
									)}
						</th>
					))}
				</tr>
			))}
		</thead>
	);
};

type BodyProps = {
	className?: string;
};

Table.Body = function Body({ className }: BodyProps) {
	const { table } = useContext(TableContext);

	if (!table) {
		return null;
	}

	return (
		<tbody className={cn("divide-y divide-neutral-200", className)}>
			{table.getRowModel().rows.map((row) => (
				<tr key={row.id}>
					{row.getVisibleCells().map((cell) => (
						<td
							key={cell.id}
							className="text-left py-2 font-medium text-neutral-600 max-w-[12rem]"
						>
							{flexRender(cell.column.columnDef.cell, cell.getContext())}
						</td>
					))}
				</tr>
			))}
		</tbody>
	);
};

// Table.Pagination = function Pagination() {
//   const { table } = useContext(TableContext);

//   if (!table) {
//     return null;
//   }

//   return (
//     <div className="w-full py-2 flex justify-center items-center space-x-4">
//       <Button
//         intent="outline"
//         className="border rounded p-1"
//         onClick={() => table.setPageIndex(0)}
//         disabled={!table.getCanPreviousPage()}
//       >
//         {"<<"}
//       </Button>
//       <Button
//         intent="outline"
//         className="border rounded p-1"
//         onClick={() => table.previousPage()}
//         disabled={!table.getCanPreviousPage()}
//       >
//         {"<"}
//       </Button>
//       <Button
//         intent="outline"
//         className="border rounded p-1"
//         onClick={() => table.nextPage()}
//         disabled={!table.getCanNextPage()}
//       >
//         {">"}
//       </Button>
//       <Button
//         intent="outline"
//         className="border rounded p-1"
//         onClick={() => table.setPageIndex(table.getPageCount() - 1)}
//         disabled={!table.getCanNextPage()}
//       >
//         {">>"}
//       </Button>
//       <span className="text-sm text-neutral-800">
//         Page {table.getState().pagination.pageIndex + 1} sur{" "}
//         {table.getPageCount()}
//       </span>
//       <span className="text-sm text-neutral-800">
//         {table.getRowModel().rows.length} résultats
//       </span>
//     </div>
//   );
// };
