import { z } from "zod";
import { bankSchema } from "./bank";
import { paymentSchema, paymentStatusSchema } from "./payment";

export const detailedPaymentSchema = paymentSchema.extend({
	bank: bankSchema
		.extend({
			paymentRail: z.string().optional(),
			iban: z.string().optional(),
		})
		.nullable(),
	onRampDestination: z
		.object({
			currency: z.string(),
			address: z.string(),
			paymentRail: z.string(),
			gasFee: z.coerce.number(),
			exchangeFee: z.coerce.number(),
			finalAmount: z.coerce.number(),
			developerFee: z.coerce.number(),
			exchangeRate: z.coerce.number(),
			initialAmount: z.coerce.number(),
			subtotalAmount: z.coerce.number(),
			convertedAmount: z.coerce.number(),
			txHash: z.string(),
		})
		.nullable(),
	timeline: z.array(
		z.object({
			status: paymentStatusSchema,
			timestamp: z.coerce.date(),
		}),
	),
	linkId: z.string().nullable(),
});

export type DetailedPayment = z.infer<typeof detailedPaymentSchema>;
