import {
	Link,
	createRoute,
	useNavigate,
	useSearch,
} from "@tanstack/react-router";
import {
	createColumnHelper,
	getCoreRowModel,
	useReactTable,
} from "@tanstack/react-table";
import { useWindowSize } from "@uidotdev/usehooks";
import dayjs from "dayjs";
import { useMemo } from "react";
import { z } from "zod";
import { usePayouts } from "../../../api/usePayouts";
import { NoData } from "../../../components/charts/no-data";
import { Loading } from "../../../components/loading";
import { StatusPill } from "../../../components/payment/status-pill";
import { Button } from "../../../components/ui/button";
import { Table } from "../../../components/ui/table";
import { dashLayout } from "../../../layouts/dash";
import { cn } from "../../../lib/cn";
import { numberFormatter } from "../../../lib/number";
import type { Payout } from "../../../schemas/payout";

const payoutsSearchParamsSchema = z.object({
	page: z.number().optional().default(1),
});

export const dashPayoutsRoute = createRoute({
	getParentRoute: () => dashLayout,
	path: "/payouts",
	component: PayoutsPage,
	validateSearch: payoutsSearchParamsSchema,
});

function PayoutsPage() {
	const { page } = useSearch({ from: "/dash/payouts" });
	const navigate = useNavigate({ from: "/payouts" });
	const { payouts, pagination, isLoading } = usePayouts({
		page,
	});

	const { width } = useWindowSize();
	const isMobile = (width ?? 1200) <= 640;

	// biome-ignore lint/correctness/useExhaustiveDependencies: refresh on status and page change
	const data = useMemo(() => {
		if (!payouts) {
			return [];
		}

		return payouts;
	}, [page]);

	const columnHelper = createColumnHelper<Payout>();

	const columns = [
		// columnHelper.display({
		//   id: "select",
		//   header: () => <input type="checkbox" />,
		//   cell: (props) => <input type="checkbox" />,
		// }),
		columnHelper.accessor("amountInCents", {
			header: "Requested amount",
			cell: ({ getValue, row }) => (
				<Link
					to="/payout/$id"
					params={{
						id: row.original.id,
					}}
					className="w-full h-full flex space-x-1"
				>
					{numberFormatter(getValue() / 100, {
						style: "currency",
						currency: row.original.currency,
					})}
				</Link>
			),
		}),
		columnHelper.accessor("payment.finalAmount", {
			header: "Received amount",
			cell: ({ getValue, row }) => {
				if (row.original.status !== "COMPLETED") {
					return "–";
				}

				return (
					<Link
						to="/payout/$id"
						params={{
							id: row.original.id,
						}}
						className="w-full h-full flex space-x-1"
					>
						{numberFormatter(getValue(), {
							style: "currency",
							currency: row.original.payment.finalCurrency,
						})}
					</Link>
				);
			},
		}),
		...(!isMobile
			? [
					columnHelper.accessor("status", {
						header: "Status",
						cell: ({ getValue, row }) => (
							<Link
								to="/payout/$id"
								params={{
									id: row.original.id,
								}}
								className="block w-full h-full"
							>
								<StatusPill status={getValue()} />
							</Link>
						),
					}),
				]
			: []),
		columnHelper.accessor("createdAt", {
			header: "Date",
			cell: ({ getValue, row }) => (
				<Link
					to="/payout/$id"
					params={{
						id: row.original.id,
					}}
					className="block w-full h-full whitespace-nowrap truncate"
				>
					{dayjs(getValue()).format("MMM DD, H:mm a")}
				</Link>
			),
		}),
	];

	const table = useReactTable({
		columns,
		data: (payouts.length || 0) > data.length ? payouts : data,
		getCoreRowModel: getCoreRowModel(),
	});

	return (
		<div className="md:py-6 px-6 md:px-12 w-full flex flex-col space-y-4">
			<h1 className="font-semibold text-2xl">Payouts</h1>
			<div className="flex flex-col">
				<div className="flex space-x-2 border-b border-neutral-300">
					<Link
						to="/balance"
						activeProps={{
							className: "border-b-2 border-inflow-800",
						}}
						className="pb-2"
						activeOptions={{
							includeSearch: false,
						}}
					>
						{({ isActive }) => (
							<span
								className={cn(
									"px-2 py-1 text-sm text-neutral-700 font-medium rounded-md",
									isActive
										? "text-inflow-800 hover:bg-inflow-300/20"
										: "text-neutral-700 hover:bg-neutral-100",
								)}
							>
								Overview
							</span>
						)}
					</Link>
					<Link
						to="/payouts"
						search={{
							page: 1,
						}}
						activeProps={{
							className: "border-b-2 border-inflow-800",
						}}
						className="pb-2"
						activeOptions={{
							includeSearch: false,
						}}
					>
						{({ isActive }) => (
							<span
								className={cn(
									"px-2 py-1 text-sm font-medium rounded-md",
									isActive
										? "text-inflow-800 hover:bg-inflow-300/20"
										: "text-neutral-700 hover:bg-neutral-100",
								)}
							>
								Payouts
							</span>
						)}
					</Link>
				</div>
				<Table table={table}>
					<table className="w-full table-fixed">
						<Table.Header />
						{!isLoading && <Table.Body />}
					</table>
					{isLoading && <Loading className="w-full py-36" />}
					{!isLoading && table.getRowModel().rows.length === 0 && (
						<NoData className="m-4" />
					)}
				</Table>
			</div>
			<div className="w-full py-2 flex flex-col md:flex-row justify-center items-center space-y-4 md:space-x-4 md:space-y-0">
				<div className="flex justify-center items-center space-x-4">
					<Button
						intent="outline"
						className="border rounded p-1"
						onClick={() =>
							navigate({
								from: dashPayoutsRoute.to,
								to: dashPayoutsRoute.to,
								search: (current) => ({
									...current,
									page: 1,
								}),
							})
						}
						disabled={page <= 1}
					>
						{"<<"}
					</Button>
					<Button
						intent="outline"
						className="border rounded p-1"
						onClick={() =>
							navigate({
								from: dashPayoutsRoute.to,
								to: dashPayoutsRoute.to,
								search: (current) => ({
									...current,
									page: pagination.currentPage - 1,
								}),
							})
						}
						disabled={page <= 1}
					>
						{"<"}
					</Button>
					<Button
						intent="outline"
						className="border rounded p-1"
						onClick={() =>
							navigate({
								from: dashPayoutsRoute.to,
								to: dashPayoutsRoute.to,
								search: (current) => ({
									...current,
									page: pagination.currentPage + 1,
								}),
							})
						}
						disabled={page >= pagination.lastPage}
					>
						{">"}
					</Button>
					<Button
						intent="outline"
						className="border rounded p-1"
						onClick={() =>
							navigate({
								from: dashPayoutsRoute.to,
								to: dashPayoutsRoute.to,
								search: (current) => ({
									...current,
									page: pagination.lastPage,
								}),
							})
						}
						disabled={page >= pagination.lastPage}
					>
						{">>"}
					</Button>
				</div>
				<div className="flex justify-center items-center space-x-4">
					<span className="text-sm text-neutral-800">
						Page {pagination.currentPage ?? 0} out of {pagination.lastPage ?? 0}
					</span>
					<span className="text-sm text-neutral-800">
						{pagination.total ?? 0} total results
					</span>
				</div>
			</div>
		</div>
	);
}
