import { useContext } from "react";
import { BankIcon } from "../../assets/bank";
import { PolygonIcon } from "../../assets/crypto/polygon";
import { XMarkIcon } from "../../assets/xmark";
import { ModalContext } from "../../providers/modal";
import type { ExternalAccount } from "../../schemas/external-account";
import { Button } from "../ui/button";

const iconMap = {
	polygon: <PolygonIcon className="w-4 h-4 fill-neutral-700" />,
};

type Props = {
	account: ExternalAccount;
	onConfirm: () => void;
};

export function ConfirmDeleteModal({ account, onConfirm }: Props) {
	const ctx = useContext(ModalContext);

	return (
		<div className="w-full max-w-lg flex flex-col space-y-4 bg-white rounded-md">
			<div className="pt-4 px-6 flex justify-between items-center">
				<span className="font-medium">
					Are you sure you want to delete this{" "}
					{account.object === "bank-account" ? "bank account" : "wallet"}?
				</span>
				<button type="button" onClick={() => ctx?.close()}>
					<XMarkIcon className="w-4 h-4" />
				</button>
			</div>
			<div className="px-6 py-4 flex items-center space-x-4 bg-neutral-100 border-y border-neutral-200">
				<div className="p-2 bg-neutral-200 rounded-md">
					{account.object === "bank-account" ? (
						<BankIcon className="w-4 h-4 fill-neutral-700" />
					) : (
						iconMap[account.network]
					)}
				</div>
				{account.object === "bank-account" ? (
					<div className="flex flex-col">
						<code className="text-sm">{account.accountOwnerName}</code>
						<code className="text-sm">
							{account.iban} / {account.bic}
						</code>
					</div>
				) : (
					<div className="flex flex-col">
						<code className="text-sm">
							<code className="capitalize">{account.network}</code> wallet
						</code>
						<code className="text-sm">{account.address}</code>
					</div>
				)}
			</div>
			<div className="pb-4 px-6 flex justify-end items-center space-x-4">
				<Button onClick={() => ctx?.close()}>Cancel</Button>
				<Button type="submit" color="red" intent="outline" onClick={onConfirm}>
					Delete
				</Button>
			</div>
		</div>
	);
}
