import { getAccessToken } from "@privy-io/react-auth";
import { queryOptions, useQuery } from "@tanstack/react-query";
import { z } from "zod";
import { fetcher } from "../lib/fetcher";
import { type Pagination, paginationSchema } from "../schemas/pagination";
import { payoutSchema } from "../schemas/payout";

type Filters = {
	page?: number;
	limit?: number;
};

async function getPayouts(filters: Filters) {
	const token = await getAccessToken();

	if (!token) {
		throw new Error("Unauthorized");
	}

	const searchParams = new URLSearchParams();

	searchParams.set("page", filters.page?.toString() ?? "1");
	searchParams.set("limit", filters.limit?.toString() ?? "15");

	return fetcher(
		`${
			import.meta.env.VITE_API_URL
		}/dashboard/withdraw/withdrawals?${searchParams.toString()}`,
		z.object({
			data: payoutSchema.array(),
			meta: paginationSchema,
		}),
		{
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		},
	);
}

export function payoutsOptions(filters: Filters) {
	return queryOptions({
		queryKey: ["payouts", filters.page, filters.limit],
		queryFn: () => getPayouts(filters),
		staleTime: 5 * 1000,
	});
}

export function usePayouts(filters: Filters) {
	const { data, ...query } = useQuery(payoutsOptions(filters));

	return {
		payouts: data?.data || [],
		pagination: data?.meta || ({} as Pagination),
		...query,
	};
}
