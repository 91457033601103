import { Outlet, createRoute, redirect } from "@tanstack/react-router";
import { NotFound } from "../components/not-found";
import { getUser } from "../lib/get-user";
import { rootRoute } from "./_root";

export const emptyLayout = createRoute({
	id: "empty",
	getParentRoute: () => rootRoute,
	component: EmptyLayout,
	loader: async ({ context }) => {
		const user = await getUser(context.accessToken);

		if (!user) {
			throw redirect({
				to: "/login",
				search: {
					next: context.location.href,
				},
			});
		}

		if (user?.legalName === null) {
			throw redirect({
				to: "/merchant",
				search: {
					next: context.location.href,
				},
			});
		}

		return {
			user,
		};
	},
	notFoundComponent: () => <NotFound className="h-[calc(100vh_-_60px)]" />,
});

function EmptyLayout() {
	return <Outlet />;
}
