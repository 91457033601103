import { useSuspenseQuery } from "@tanstack/react-query";
import { Link, createRoute, useParams } from "@tanstack/react-router";
import dayjs from "dayjs";
import { payoutOptions } from "../../../api/usePayout";
import { ChevronIcon } from "../../../assets/chevron";
import { NoData } from "../../../components/charts/no-data";
import { StatusPill } from "../../../components/payment/status-pill";
import { ObjectType } from "../../../components/ui/object-type";
import { queryClient } from "../../../layouts/_root";
import { dashLayout } from "../../../layouts/dash";
import { numberFormatter } from "../../../lib/number";

export const dashPayoutRoute = createRoute({
	getParentRoute: () => dashLayout,
	path: "/payout/$id",
	component: PayoutPage,
	loader: async ({ params }) => {
		await queryClient.ensureQueryData(payoutOptions(params.id));
	},
});

function PayoutPage() {
	const { id } = useParams({ from: "/dash/payout/$id" });
	const { data: payout } = useSuspenseQuery(payoutOptions(id));

	if (!payout) {
		return null;
	}

	return (
		<div className="md:py-6 px-6 md:px-12 w-full flex flex-col space-y-12">
			<div className="flex flex-col space-y-1">
				<Link
					to="/payouts"
					search={{ page: 1 }}
					className="flex items-center space-x-2 text-sm font-semibold text-inflow-800 hover:underline"
				>
					<ChevronIcon className="mb-px w-3 h-3 fill-inflow-800 rotate-90" />
					<span>Payouts</span>
				</Link>
				<div className="flex space-x-2 items-center">
					<h1 className="font-semibold text-2xl">
						{numberFormatter(payout.amountInCents / 100, {
							style: "currency",
							currency: payout.currency,
						})}{" "}
						{payout.currency}
					</h1>
					<StatusPill status={payout.status} />
				</div>
			</div>
			<div className="flex flex-col lg:flex-row space-x-0 space-y-12 lg:space-x-12 lg:space-y-0">
				<div className="flex-1 flex flex-col space-y-12">
					<section className="flex flex-col space-y-4">
						<div className="flex space-x-2 items-center border-b border-neutral-300 pb-2">
							<h2 className="font-semibold text-xl">Timeline</h2>
						</div>
						<div className="flex flex-col space-y-3">
							{payout.timeline.length > 0 ? (
								payout.timeline.map((t) => (
									<div key={t.status} className="flex flex-col space-y-1">
										<span className="text-neutral-700">{t.label}</span>
										<span className="text-neutral-500 text-sm">
											{dayjs(t.timestamp).format("MMM DD, H:mm a")}
										</span>
									</div>
								))
							) : (
								<NoData className="mx-2 md:mx-4" />
							)}
						</div>
					</section>
					<section className="flex flex-col space-y-4">
						<div className="flex space-x-2 items-center border-b border-neutral-300 pb-2">
							<h2 className="font-semibold text-xl">Summary</h2>
						</div>
						<div className="flex flex-col space-y-2">
							<div className="flex items-center justify-between">
								<span className="w-40 text-neutral-500">Amount</span>
								<span>
									{numberFormatter(payout.payment.sourceAmount, {
										style: "currency",
										currency: payout.currency,
									})}
								</span>
							</div>
							<div className="flex items-center justify-between">
								<span className="w-40 text-neutral-500">Rate</span>
								{payout.status === "COMPLETED" ? (
									<div className="flex space-x-2 uppercase">
										<span>1 {payout.currency}</span>
										<span>{">"}</span>
										<span>
											{payout.payment.exchangeRate}{" "}
											{payout.payment.finalCurrency}
										</span>
									</div>
								) : (
									<span>–</span>
								)}
							</div>
							<div className="flex items-center justify-between">
								<span className="w-40 text-neutral-500">Net</span>
								{payout.status === "COMPLETED" ? (
									<span className="uppercase">
										{numberFormatter(payout.payment.finalAmount, {
											style: "currency",
											currency: payout.payment.finalCurrency,
										})}
									</span>
								) : (
									<span>–</span>
								)}
							</div>
						</div>
					</section>
					<section className="flex flex-col space-y-4">
						<div className="flex space-x-2 items-center border-b border-neutral-300 pb-2">
							<h2 className="font-semibold text-xl">Account details</h2>
						</div>
						{payout.account ? (
							<div className="flex flex-col space-y-2">
								<div className="flex items-center justify-between">
									<span className="w-40 text-neutral-500">Type</span>
									<span>
										{payout.account.object === "bank-account"
											? "Bank account"
											: "Wallet"}
									</span>
								</div>
								<div className="flex items-center justify-between">
									<span className="w-40 text-neutral-500">
										{payout.account.object === "bank-account"
											? "IBAN"
											: "Address"}
									</span>
									<span className="font-mono text-sm">
										{payout.account.object === "bank-account"
											? `••••${payout.account.iban}`
											: `${payout.account.address.slice(
													0,
													6,
												)}••••${payout.account.address.slice(-4)}`}
									</span>
								</div>
								<div className="flex items-center justify-between">
									<span className="w-40 text-neutral-500">
										{payout.account.object === "bank-account"
											? "BIC"
											: "Network"}
									</span>
									<span className="font-mono text-sm">
										{payout.account.object === "bank-account"
											? payout.account.bic
											: payout.account.network}
									</span>
								</div>
							</div>
						) : (
							<NoData className="my-4 h-52" />
						)}
					</section>
				</div>
				<section className="min-w-80 flex flex-col space-y-4">
					<div className="flex space-x-2 items-center border-b border-neutral-300 pb-2">
						<h2 className="font-semibold text-xl">Details</h2>
					</div>
					<div className="flex flex-col space-y-4">
						<div className="flex flex-col space-y-1">
							<span className="text-neutral-700 text-sm font-semibold">
								Sent at
							</span>
							<span className="text-neutral-500 text-sm">
								{payout.sentAt
									? dayjs(payout.sentAt).format("MMM DD, H:mm a")
									: "–"}
							</span>
						</div>
						<div className="flex flex-col space-y-1">
							<span className="text-neutral-700 text-sm font-semibold">
								Completed at
							</span>
							<span className="text-neutral-500 text-sm">
								{payout.completedAt
									? dayjs(payout.completedAt).format("MMM DD, H:mm a")
									: "–"}
							</span>
						</div>
						<div className="flex flex-col space-y-1">
							<span className="text-neutral-700 text-sm font-semibold">
								Payment rail
							</span>
							<span className="text-neutral-500 text-sm">
								{payout.payment.rail.toUpperCase()}
							</span>
						</div>
						<div className="flex flex-col space-y-1">
							<span className="text-neutral-700 text-sm font-semibold">
								Payout ID
							</span>
							<ObjectType id={payout.id} />
						</div>
					</div>
				</section>
			</div>
		</div>
	);
}
