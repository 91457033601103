import { Link } from "@tanstack/react-router";
import {
	createColumnHelper,
	getCoreRowModel,
	useReactTable,
} from "@tanstack/react-table";
import dayjs from "dayjs";
import { useWindowSize } from "usehooks-ts";
import { numberFormatter } from "../../lib/number";
import { NoData } from "../charts/no-data";
import { Table } from "../ui/table";

type LinkPayment = {
	id: string;
	amount: number;
	currency: string;
	email: string | null;
	createdAt: Date;
	updatedAt: Date;
};

type Props = {
	payments: LinkPayment[];
};

export function RecentPayments({ payments }: Props) {
	const { width } = useWindowSize();
	const isMobile = (width ?? 1200) <= 640;

	const columnHelper = createColumnHelper<LinkPayment>();

	const columns = [
		columnHelper.accessor("amount", {
			header: "Amount",
			cell: ({ getValue, row }) => (
				<Link
					to="/payment/$id"
					params={{
						id: row.original.id,
					}}
					className="block w-full h-full"
				>
					{numberFormatter(+getValue() / 100, {
						style: "currency",
						currency: row.original.currency,
					})}
				</Link>
			),
		}),
		// columnHelper.accessor("status", {
		//   header: "Status",
		//   cell: ({ getValue, row }) => (
		//     <Link
		//       to="/payment/$id"
		//       params={{
		//         id: row.original.id,
		//       }}
		//       className="block w-full h-full"
		//     >
		//       <StatusPill status={getValue()} />
		//     </Link>
		//   ),
		// }),
		columnHelper.accessor("createdAt", {
			header: "Date",
			cell: ({ getValue, row }) => (
				<Link
					to="/payment/$id"
					params={{
						id: row.original.id,
					}}
					className="block w-full h-full"
				>
					{dayjs(getValue()).format("MMM DD, H:mm a")}
				</Link>
			),
		}),
		...(!isMobile
			? [
					columnHelper.display({
						header: "Type",
						cell: ({ row }) => (
							<Link
								to="/payment/$id"
								params={{
									id: row.original.id,
								}}
								className="block w-full h-full"
							>
								One-time
							</Link>
						),
					}),
					// columnHelper.accessor("bank", {
					//   header: "Bank",
					//   cell: ({ getValue, row }) => {
					//     const bank = getValue();

					//     if (!bank) {
					//       return "–";
					//     }

					//     return (
					//       <Link
					//         to="/payment/$id"
					//         params={{
					//           id: row.original.id,
					//         }}
					//         className="block w-full h-full"
					//       >
					//         <div className="w-fit flex items-center justify-between space-x-2">
					//           <img src={bank.logo} alt={bank.name} className="w-5 h-5" />
					//           <span>{bank.name}</span>
					//         </div>
					//       </Link>
					//     );
					//   },
					// }),
				]
			: []),
	];

	const table = useReactTable({
		columns,
		data: payments,
		getCoreRowModel: getCoreRowModel(),
	});

	return (
		<Table table={table} className="border-t-0">
			<table className="w-full table-fixed">
				<Table.Header />
				<Table.Body />
			</table>
			{table.getRowModel().rows.length === 0 && <NoData className="m-4" />}
		</Table>
	);
}
