import dayjs from "dayjs";

type Props = {
	active?: boolean;
	// biome-ignore lint/suspicious/noExplicitAny: whatever recharts gives us
	payload?: any[];
	formatter?: (value: number) => string;
};

export function CustomTooltip({ active, payload, formatter }: Props) {
	if (!active || !payload || payload.length === 0) {
		return null;
	}

	return (
		<div className="flex items-center px-2 py-1 space-x-4 bg-white border border-neutral-200 rounded-md shadow-sm">
			<span className="text-sm font-semibold text-neutral-500">
				{dayjs(payload[0].payload.date).format("D MMM, YYYY")}
			</span>
			<span className="text-sm font-semibold text-neutral-600">{`${
				formatter
					? formatter(payload[0].value as number)
					: Math.round(payload[0].value * 10) / 10
			}`}</span>
		</div>
	);
}
