import { z } from "zod";

export const productSchema = z.object({
	id: z.string(),
	name: z.string(),
	price: z.number(),
	quantity: z.number(),
	paymentId: z.string().nullable(),
	linkId: z.string().nullable(),
	createdAt: z.coerce.date(),
	// updatedAt: z.coerce.date(),
});

export type Product = z.infer<typeof productSchema>;
