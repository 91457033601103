import { getAccessToken } from "@privy-io/react-auth";
import {
	queryOptions,
	useQuery,
	useSuspenseQuery,
} from "@tanstack/react-query";
import { z } from "zod";
import { fetcher } from "../lib/fetcher";
import type { QueryOptions } from "./types";

const objectSchema = z.enum(["payment", "payout", "link"]);

const objectPaths: Record<z.infer<typeof objectSchema>, string> = {
	payment: "/payment/$id",
	payout: "/payout/$id",
	link: "/link/$id",
};

type Options = QueryOptions<Awaited<ReturnType<typeof getSearch>>>;

async function getSearch(term: string) {
	const token = await getAccessToken();

	if (!token) {
		throw new Error("Unauthorized");
	}

	const searchParams = new URLSearchParams();

	searchParams.set("query", term);

	const results = await fetcher(
		`${import.meta.env.VITE_API_URL}/dashboard/search?${searchParams}`,
		z
			.object({
				id: z.string(),
				object: objectSchema,
			})
			.array(),
		{
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		},
	);

	return results.map((res) => ({
		...res,
		to: objectPaths[res.object],
	}));
}

export function searchOptions(term: string, options: Options) {
	return queryOptions({
		queryKey: ["search", term],
		queryFn: () => getSearch(term),
		staleTime: 10 * 1000,
		...options,
	});
}

export function useSearch(term: string, options: Options = {}) {
	const { data, ...query } = useQuery(searchOptions(term, options));

	return {
		search: data,
		...query,
	};
}

export function useSuspenseSearch(term: string, options: Options = {}) {
	const { data, ...query } = useSuspenseQuery(searchOptions(term, options));

	return {
		search: data,
		...query,
	};
}
