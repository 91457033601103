import { getAccessToken } from "@privy-io/react-auth";
import {
	queryOptions,
	useQuery,
	useSuspenseQuery,
} from "@tanstack/react-query";
import { z } from "zod";
import { fetcher } from "../lib/fetcher";

async function getMerchant() {
	const token = await getAccessToken();

	if (!token) {
		throw new Error("Unauthorized");
	}

	return fetcher(
		`${import.meta.env.VITE_API_URL}/dashboard/merchant`,
		z.object({
			merchantName: z.string(),
			country: z.string().nullable(),
			// capabilities: z.object({
			// 	canAddWallet: z.boolean(),
			// 	canAddBankAccount: z.boolean(),
			// }),
		}),
		{
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		},
	);
}

export function merchantOptions(opts?: { enabled?: boolean }) {
	return queryOptions({
		queryKey: ["merchant"],
		queryFn: () => getMerchant(),
		staleTime: 10 * 1000,
		...opts,
	});
}

export function useMerchant(opts?: { enabled?: boolean }) {
	const { data, ...query } = useQuery(merchantOptions(opts));

	return {
		merchant: data,
		...query,
	};
}

export function useSuspenseMerchant(opts?: { enabled?: boolean }) {
	const { data, ...query } = useSuspenseQuery(merchantOptions(opts));

	return {
		merchant: data,
		...query,
	};
}
