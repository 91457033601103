import { zodResolver } from "@hookform/resolvers/zod";
import { electronicFormatIBAN, isValidBIC, isValidIBAN } from "ibantools";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useAddBankAccount } from "../../api/mutations/use-add-bank-account";
import { XMarkIcon } from "../../assets/xmark";
import { queryClient } from "../../layouts/_root";
import { ModalContext } from "../../providers/modal";
import { Button } from "../ui/button";

const formValuesSchema = z.object({
	accountOwnerName: z.string().min(1, "Account Holder is required"),
	iban: z
		.string()
		.min(1, "IBAN is required")
		.refine(
			(iban) => {
				const formattedIBAN = electronicFormatIBAN(iban);

				if (formattedIBAN === null) {
					return false;
				}

				return isValidIBAN(formattedIBAN);
			},
			{
				message: "Invalid IBAN",
			},
		),
	bic: z.string().min(1, "BIC is required").refine(isValidBIC, {
		message: "Invalid BIC",
	}),
});

type FormValues = z.infer<typeof formValuesSchema>;

export function AddBankAccountModal() {
	const ctx = useContext(ModalContext);
	const { mutate, isPending, isError } = useAddBankAccount({
		onSuccess: async () => {
			await queryClient.invalidateQueries({ queryKey: ["external-accounts"] });
			ctx?.close();
		},
	});
	const {
		handleSubmit,
		register,
		formState: { errors },
	} = useForm<FormValues>({
		resolver: zodResolver(formValuesSchema),
		mode: "onSubmit",
		reValidateMode: "onChange",
	});

	async function onSubmit(data: FormValues) {
		mutate(data);
	}

	return (
		<div className="w-full max-w-lg flex flex-col space-y-4 bg-white rounded-md">
			<div className="pt-4 px-6 flex justify-between items-center">
				<span className="font-medium">Add bank account</span>
				<button disabled={isPending} type="button" onClick={() => ctx?.close()}>
					<XMarkIcon className="w-4 h-4" />
				</button>
			</div>
			<form
				onSubmit={handleSubmit(onSubmit)}
				className="w-full flex flex-col space-y-4"
			>
				{isError ? (
					<div className="px-6 py-4 flex items-center space-x-4 bg-red-100 border-y border-red-200">
						<span className="text-sm text-red-700">
							We couldn't add your bank account at this time, please try again
							later
						</span>
					</div>
				) : null}
				<div className="px-6 w-full grid grid-cols-2 gap-4">
					<div className="col-span-2 flex flex-col space-y-1">
						<div className="flex items-center justify-between">
							<label
								htmlFor="accountOwnerName"
								className="text-sm text-neutral-700"
							>
								Account Holder
							</label>
							{errors.accountOwnerName && (
								<span className="text-sm text-red-500">
									{errors.accountOwnerName.message}
								</span>
							)}
						</div>
						<input
							id="accountOwnerName"
							type="text"
							placeholder="David Keller"
							className="w-full px-3 py-1.5 text-sm border border-neutral-200 hover:border-neutral-300 focus:border-neutral-300 outline-none rounded-md transition-colors duration-300 ease-in-out"
							{...register("accountOwnerName")}
						/>
					</div>
					<div className="flex flex-col space-y-1">
						<div className="flex items-center justify-between">
							<label htmlFor="iban" className="text-sm text-neutral-700">
								IBAN
							</label>
							{errors.iban && (
								<span className="text-sm text-red-500">
									{errors.iban.message}
								</span>
							)}
						</div>
						<input
							id="iban"
							type="text"
							placeholder="IBAN"
							className="w-full px-3 py-1.5 text-sm border border-neutral-200 hover:border-neutral-300 focus:border-neutral-300 outline-none rounded-md transition-colors duration-300 ease-in-out"
							{...register("iban")}
						/>
					</div>
					<div className="flex flex-col space-y-1">
						<div className="flex items-center justify-between">
							<label htmlFor="bic" className="text-sm text-neutral-700">
								BIC
							</label>
							{errors.bic && (
								<span className="text-sm text-red-500">
									{errors.bic.message}
								</span>
							)}
						</div>
						<input
							id="bic"
							type="text"
							placeholder="BIC"
							className="w-full px-3 py-1.5 text-sm border border-neutral-200 hover:border-neutral-300 focus:border-neutral-300 outline-none rounded-md transition-colors duration-300 ease-in-out"
							{...register("bic")}
						/>
					</div>
				</div>
				<div className="px-6 pb-4 flex justify-end items-center space-x-4">
					<Button disabled={isPending} onClick={() => ctx?.close()}>
						Cancel
					</Button>
					<Button
						disabled={isPending}
						type="submit"
						color="inflow"
						intent="solid"
					>
						{isPending ? "Loading..." : "Save"}
					</Button>
				</div>
			</form>
		</div>
	);
}
