import { Menu, Transition } from "@headlessui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { getAccessToken } from "@privy-io/react-auth";
import { useMutation } from "@tanstack/react-query";
import { useParams } from "@tanstack/react-router";
import { Fragment, useContext } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useUpdateLinkStatus } from "../../api/mutations/use-update-link-status";
import { useLink } from "../../api/use-link";
import { DotsIcon } from "../../assets/dots";
import { XMarkIcon } from "../../assets/xmark";
import { queryClient } from "../../layouts/_root";
import { cn } from "../../lib/cn";
import { fetcher } from "../../lib/fetcher";
import { ModalContext } from "../../providers/modal";
import { Button } from "../ui/button";

export function LinkSettings() {
	const ctx = useContext(ModalContext);
	const { id } = useParams({ from: "/dash/link/$id" });
	const { link } = useLink(id);
	const { mutate: updateLinkStatus } = useUpdateLinkStatus(id);

	return (
		<Menu as="div" className="relative">
			<Menu.Button
				as="button"
				className="h-full px-3 py-1.5 flex items-center justify-between space-x-1 border border-neutral-200 bg-neutral-50 rounded-lg"
			>
				<DotsIcon className="w-4 h-4 fill-inflow-700" />
			</Menu.Button>
			<Transition
				as={Fragment}
				enter="transition duration-100 ease-out"
				enterFrom="transform scale-95 opacity-0"
				enterTo="transform scale-100 opacity-100"
				leave="transition duration-75 ease-out"
				leaveFrom="transform scale-100 opacity-100"
				leaveTo="transform scale-95 opacity-0"
			>
				<Menu.Items className="absolute max-h-72 overflow-auto right-0 z-30 mt-2 flex w-48 origin-top-right flex-col divide-y divide-neutral-200 rounded-md border border-y border-neutral-200 bg-white shadow-sm">
					<div className="flex w-full flex-col space-y-1 px-1 py-1">
						<Menu.Item
							as="button"
							className="rounded-md hover:bg-neutral-100 focus:bg-neutral-100"
							onClick={() => {
								if (!link) {
									return;
								}

								ctx?.open(<EditNameModal id={link.id} name={link.name} />);
							}}
						>
							<span className="block px-2 py-1 text-left font-medium text-sm text-neutral-700">
								Edit name
							</span>
						</Menu.Item>
					</div>
					<div className="flex w-full flex-col space-y-1 px-1 py-1">
						<Menu.Item
							as="button"
							className={cn(
								"rounded-md",
								link?.status === "enabled"
									? "hover:bg-red-100 focus:bg-red-100"
									: "hover:bg-inflow-700/10 focus:bg-inflow-700/10",
							)}
							onClick={() => {
								if (!link) {
									return;
								}

								if (link.status === "enabled") {
									updateLinkStatus("disabled");
								} else {
									updateLinkStatus("enabled");
								}
							}}
						>
							<span
								className={cn(
									"block px-2 py-1 text-left font-medium text-sm",
									link?.status === "enabled"
										? "text-red-600"
										: "text-inflow-700",
								)}
							>
								{link?.status === "enabled" ? "Disable" : "Enable"}
							</span>
						</Menu.Item>
					</div>
				</Menu.Items>
			</Transition>
		</Menu>
	);
}

const formValuesSchema = z.object({
	name: z.string(),
});

type FormValues = z.infer<typeof formValuesSchema>;

type EditNameModalProps = {
	id: string;
	name: string;
};

export function EditNameModal({ id, name }: EditNameModalProps) {
	const ctx = useContext(ModalContext);
	const {
		handleSubmit,
		register,
		formState: { errors },
	} = useForm<FormValues>({
		resolver: zodResolver(formValuesSchema),
		mode: "onSubmit",
		reValidateMode: "onChange",
		defaultValues: {
			name,
		},
	});

	const { mutate } = useMutation({
		mutationKey: ["update-name", id],
		mutationFn: async (name: string) => {
			return fetcher(
				`${import.meta.env.VITE_API_URL}/dashboard/links/${id}`,
				z.object({
					id: z.string(),
					name: z.string(),
				}),
				{
					method: "PATCH",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${await getAccessToken()}`,
					},
					body: JSON.stringify({ name }),
				},
			);
		},
		onMutate: async (name) => {
			await queryClient.cancelQueries({ queryKey: ["link", id] });

			const prev = queryClient.getQueryData(["link", id]);

			if (typeof prev !== "object") {
				return;
			}

			queryClient.setQueryData(["link", id], () => ({
				...prev,
				name,
			}));

			return { prev };
		},
		onError: (_, __, context) => {
			queryClient.setQueryData(["link", id], context?.prev);

			alert("An error occurred updating your name. Please try again.");
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: ["link", id] });
		},
	});

	function onSubmit(data: FormValues, e?: React.BaseSyntheticEvent) {
		e?.preventDefault();

		mutate(data.name);

		ctx?.close();
	}

	return (
		<form
			className="w-full max-w-lg flex flex-col space-y-8 bg-white rounded-md"
			onSubmit={handleSubmit(onSubmit)}
		>
			<div className="pt-4 px-6 flex justify-between items-center">
				<span className="font-medium">Edit name</span>
				<button type="button" onClick={() => ctx?.close()}>
					<XMarkIcon className="w-4 h-4" />
				</button>
			</div>
			<div className="px-6 flex flex-col items-center space-y-4">
				<div className="w-full flex flex-col space-y-1">
					<div className="flex items-center justify-between">
						<label htmlFor="name" className="text-sm text-neutral-700">
							Name
						</label>
						{errors.name && (
							<span className="text-sm text-red-500">
								{errors.name.message}
							</span>
						)}
					</div>
					<input
						id="name"
						type="text"
						placeholder="Name"
						className="w-full px-3 py-1.5 text-sm border border-neutral-200 hover:border-neutral-300 focus:border-neutral-300 outline-none rounded-md transition-colors duration-300 ease-in-out"
						{...register("name")}
					/>
				</div>
			</div>
			<div className="pb-4 px-6 flex space-x-4 justify-end items-center">
				<Button onClick={() => ctx?.close()}>Cancel</Button>
				<Button type="submit" color="inflow" intent="solid">
					Save
				</Button>
			</div>
		</form>
	);
}
