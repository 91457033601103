import { useSuspenseQuery } from "@tanstack/react-query";
import { Link, createRoute, useParams } from "@tanstack/react-router";
import { useCopyToClipboard } from "@uidotdev/usehooks";
import dayjs from "dayjs";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import QRCode from "react-qr-code";
import { linkOptions } from "../../../api/use-link";
import { useMerchant } from "../../../api/useMerchant";
import { CheckIcon } from "../../../assets/check";
import { ChevronIcon } from "../../../assets/chevron";
import { CopyIcon } from "../../../assets/copy";
import { QRIcon } from "../../../assets/qr";
import { ShareIcon } from "../../../assets/share";
import { XMarkIcon } from "../../../assets/xmark";
import { LinkSettings } from "../../../components/links/link-settings";
import { RecentPayments } from "../../../components/links/recent-payments";
import { StatusPill } from "../../../components/payment/status-pill";
import { Button } from "../../../components/ui/button";
import { ObjectType } from "../../../components/ui/object-type";
import { queryClient } from "../../../layouts/_root";
import { dashLayout } from "../../../layouts/dash";
import { numberFormatter } from "../../../lib/number";
import { ModalContext } from "../../../providers/modal";

export const dashLinkRoute = createRoute({
	getParentRoute: () => dashLayout,
	path: "/link/$id",
	component: LinkPage,
	loader: async ({ params }) => {
		await queryClient.ensureQueryData(linkOptions(params.id));
	},
});

function LinkPage() {
	const [isCopied, setIsCopied] = useState(false);
	const [_, copyToClipboard] = useCopyToClipboard();
	const { id } = useParams({ from: "/dash/link/$id" });
	const { data: link } = useSuspenseQuery(linkOptions(id));
	const ctx = useContext(ModalContext);

	const checkoutUrl = useMemo(() => {
		if (!link) {
			return "";
		}

		return `${import.meta.env.VITE_CHECKOUT_URL}/l/${link.id}`;
	}, [link]);

	const navigatorData = useMemo(
		() => ({
			title: "Inflow Checkout",
			text: "Use this link to pay me",
			url: checkoutUrl,
		}),
		[checkoutUrl],
	);

	const canShare = "canShare" in navigator && navigator.canShare(navigatorData);

	if (!link) {
		return null;
	}

	const timeout = useRef<NodeJS.Timeout | null>(null);

	function copy() {
		if (timeout.current) {
			return;
		}

		setIsCopied(true);
		copyToClipboard(checkoutUrl);

		timeout.current = setTimeout(() => {
			setIsCopied(false);

			timeout.current = null;
		}, 1500);
	}

	useEffect(() => {
		return () => clearTimeout(timeout.current as NodeJS.Timeout);
	}, []);

	return (
		<div className="md:py-6 px-6 md:px-12 w-full flex flex-col space-y-12">
			<div className="flex flex-col space-y-6">
				<div className="flex flex-col space-y-1">
					<Link
						to="/links"
						search={{ page: 1 }}
						className="flex items-center space-x-2 text-sm font-semibold text-inflow-800 hover:underline"
					>
						<ChevronIcon className="mb-px w-3 h-3 fill-inflow-800 rotate-90" />
						<span>Links</span>
					</Link>
					<div className="flex space-x-2 items-center">
						<h1 className="font-semibold text-2xl">
							{link.name}{" "}
							<span className="text-neutral-500 font-normal text-base">
								for{" "}
							</span>
							<span className="font-semibold text-base">
								{numberFormatter(link.amount / 100, {
									style: "currency",
									currency: link.currency,
								})}{" "}
								{link.currency}
							</span>
						</h1>
						<StatusPill status={link.status} />
					</div>
				</div>
				<section
					style={
						{
							"--width-with-menu": `calc(100vw${
								link.status === "enabled"
									? canShare
										? " - 502px"
										: " - 452px"
									: " - 402px"
							})`,
							"--width-without-menu": `calc(100vw${
								link.status === "enabled"
									? canShare
										? " - 198px"
										: " - 148px"
									: " - 98px"
							})`,
						} as React.CSSProperties
					}
					className="flex space-x-2 w-fit max-w-[--width-without-menu] md:max-w-[--width-with-menu]"
				>
					<button
						type="button"
						onClick={() => copy()}
						className="px-3 py-1.5 w-full flex items-center justify-between space-x-2 border border-neutral-200 bg-neutral-50 rounded-lg"
					>
						<code className="text-sm text-neutral-700 truncate">
							{checkoutUrl}
						</code>
						{isCopied ? (
							<CheckIcon className="w-4 h-4 fill-inflow-800 flex-shrink-0" />
						) : (
							<CopyIcon className="w-4 h-4 fill-inflow-800 flex-shrink-0" />
						)}
					</button>
					{link.status === "enabled" ? (
						<button
							type="button"
							onClick={() => ctx?.open(<ShareQRCodeModal url={checkoutUrl} />)}
							className="px-3 py-1.5 flex items-center justify-between space-x-1 border border-neutral-200 bg-neutral-50 rounded-lg"
						>
							<QRIcon className="w-4 h-4 fill-inflow-800" />
						</button>
					) : null}
					{link.status === "enabled" && canShare ? (
						<button
							type="button"
							onClick={() => navigator.share(navigatorData)}
							className="px-3 py-1.5 flex items-center justify-between space-x-1 border border-neutral-200 bg-neutral-50 rounded-lg"
						>
							<ShareIcon className="w-4 h-4 fill-inflow-800" />
						</button>
					) : null}
					<LinkSettings />
				</section>
			</div>
			<div className="flex flex-col lg:flex-row space-x-0 space-y-12 lg:space-x-12 lg:space-y-0">
				<div className="flex-1 flex flex-col space-y-12">
					<section className="flex flex-col space-y-4">
						<div className="flex space-x-2 items-center border-b border-neutral-300 pb-2">
							<h2 className="font-semibold text-xl">Products</h2>
						</div>
						<div className="flex flex-col space-y-3">
							{link.products.map((product) => (
								<div
									key={product.id}
									className="w-full px-3 py-1.5 flex justify-between items-start border border-neutral-200 bg-neutral-50 rounded-lg"
								>
									<div className="flex flex-col">
										<span className="text-sm text-neutral-700">
											{product.name}
										</span>
										<span className="text-sm text-neutral-700">
											Quantity: {product.quantity}
										</span>
									</div>
									<div className="flex flex-col items-end">
										<span className="text-sm text-neutral-700">
											{numberFormatter(
												(product.price / 100) * product.quantity,
												{
													style: "currency",
													currency: "EUR",
												},
											)}
										</span>
										{product.quantity > 1 && (
											<span className="text-sm text-neutral-700">
												{numberFormatter(product.price / 100, {
													style: "currency",
													currency: "EUR",
												})}
												/each
											</span>
										)}
									</div>
								</div>
							))}
						</div>
					</section>
					<section className="flex flex-col">
						<div className="flex space-x-2 items-center border-b border-neutral-300 pb-2">
							<h2 className="font-semibold text-xl">Payments</h2>
						</div>
						<RecentPayments payments={link.payments} />
					</section>
				</div>
				<div className="min-w-80 flex flex-col space-y-12">
					<section className="flex flex-col space-y-4">
						<div className="flex space-x-2 items-center border-b border-neutral-300 pb-2">
							<h2 className="font-semibold text-xl">Details</h2>
						</div>
						<div className="flex flex-col space-y-4">
							<div className="flex flex-col space-y-1">
								<span className="text-neutral-700 text-sm font-semibold">
									Created at
								</span>
								<span className="text-neutral-500 text-sm">
									{dayjs(link.createdAt).format("MMM DD, H:mm a")}
								</span>
							</div>
							<div className="flex flex-col space-y-1">
								<span className="text-neutral-700 text-sm font-semibold">
									Link ID
								</span>
								<ObjectType id={link.id} />
							</div>
						</div>
					</section>
				</div>
			</div>
		</div>
	);
}

type ShareQRCodeModalProps = {
	url: string;
};

function ShareQRCodeModal({ url }: ShareQRCodeModalProps) {
	const ctx = useContext(ModalContext);
	const { merchant } = useMerchant();

	return (
		<div className="w-full max-w-md flex flex-col space-y-4 bg-white rounded-md">
			<div className="pt-4 px-6 flex justify-between items-center">
				<span className="font-medium">Your QR code</span>
				<button type="button" onClick={() => ctx?.close()}>
					<XMarkIcon className="w-4 h-4" />
				</button>
			</div>
			<div className="px-6 py-4 flex items-center space-x-4 bg-neutral-100 border-y border-neutral-200">
				<p className="text-sm text-neutral-700">
					Scan this code with your phone camera to pay {merchant?.merchantName}
				</p>
			</div>
			<div className="px-6 py-4 flex justify-center">
				<div className="p-6 flex flex-col items-center space-y-6 bg-inflow-400 rounded-3xl">
					<div className="p-4 bg-white rounded-xl">
						<QRCode value={url} className="w-40 h-40" width={60} height={60} />
					</div>
					<span className="text-inflow-900 font-semibold text-center">
						Scan to pay
					</span>
				</div>
			</div>
			<div className="pb-4 px-6 flex space-x-4 justify-end items-center">
				<Button onClick={() => ctx?.close()}>Close</Button>
			</div>
		</div>
	);
}
