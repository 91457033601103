import { zodResolver } from "@hookform/resolvers/zod";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import { isAddress } from "viem";
import { z } from "zod";
import { useAddWallet } from "../../api/mutations/use-add-wallet";
import { XMarkIcon } from "../../assets/xmark";
import { queryClient } from "../../layouts/_root";
import { ModalContext } from "../../providers/modal";
import { Button } from "../ui/button";

const formValuesSchema = z.object({
	address: z.string().min(1, "Address is required").refine(isAddress, {
		message: "Invalid address",
	}),
	network: z.enum(["polygon"]),
});

type FormValues = z.infer<typeof formValuesSchema>;

export function AddWalletModal() {
	const ctx = useContext(ModalContext);
	const { mutate, isPending, isError } = useAddWallet({
		onSuccess: async () => {
			await queryClient.invalidateQueries({ queryKey: ["external-accounts"] });
			ctx?.close();
		},
	});
	const {
		handleSubmit,
		register,
		formState: { errors },
	} = useForm<FormValues>({
		resolver: zodResolver(formValuesSchema),
		mode: "onSubmit",
		reValidateMode: "onChange",
	});

	async function onSubmit(data: FormValues) {
		mutate(data);
	}

	return (
		<div className="w-full max-w-lg flex flex-col space-y-8 bg-white rounded-md">
			<div className="pt-4 px-6 flex justify-between items-center">
				<span className="font-medium">Add wallet</span>
				<button disabled={isPending} type="button" onClick={() => ctx?.close()}>
					<XMarkIcon className="w-4 h-4" />
				</button>
			</div>
			<form
				onSubmit={handleSubmit(onSubmit)}
				className="w-full flex flex-col space-y-4"
			>
				{isError ? (
					<div className="px-6 py-4 flex items-center space-x-4 bg-red-100 border-y border-red-200">
						<span className="text-sm text-red-700">
							We couldn't add your wallet at this time, please try again later
						</span>
					</div>
				) : null}
				<div className="px-6 flex flex-col space-y-1">
					<div className="flex items-center justify-between">
						<label htmlFor="address" className="text-sm text-neutral-700">
							Address
						</label>
						{errors.address && (
							<span className="text-sm text-red-500">
								{errors.address.message}
							</span>
						)}
					</div>
					<input
						id="address"
						type="text"
						placeholder="0x..."
						className="w-full px-3 py-1.5 text-sm border border-neutral-200 hover:border-neutral-300 focus:border-neutral-300 outline-none rounded-md transition-colors duration-300 ease-in-out"
						{...register("address")}
					/>
				</div>
				<div className="px-6 flex flex-col space-y-1">
					<div className="flex items-center justify-between">
						<label htmlFor="network" className="text-sm text-neutral-700">
							Network
						</label>
						{errors.network && (
							<span className="text-sm text-red-500">
								{errors.network.message}
							</span>
						)}
					</div>
					<select
						id="network"
						className="w-full px-2 py-1.5 text-sm border border-neutral-200 hover:border-neutral-300 focus:border-neutral-300 outline-none rounded-md transition-colors duration-300 ease-in-out"
						{...register("network")}
					>
						<option value="polygon">Polygon</option>
					</select>
				</div>
				<div className="px-6 pb-4 flex justify-end items-center space-x-4">
					<Button disabled={isPending} onClick={() => ctx?.close()}>
						Cancel
					</Button>
					<Button
						disabled={isPending}
						type="submit"
						color="inflow"
						intent="solid"
					>
						{isPending ? "Loading..." : "Save"}
					</Button>
				</div>
			</form>
		</div>
	);
}
