import { getAccessToken } from "@privy-io/react-auth";
import { useMutation } from "@tanstack/react-query";
import { z } from "zod";
import { fetcher } from "../../lib/fetcher";
import type { MutationOptions } from "../types";

type Data = {
	accountOwnerName: string;
	iban: string;
	bic: string;
};

type Options = MutationOptions<
	Awaited<ReturnType<typeof addBankAccount>>,
	Data
>;

async function addBankAccount(data: Data) {
	return fetcher(
		`${import.meta.env.VITE_API_URL}/dashboard/account/bank`,
		z.object({
			ok: z.boolean(),
		}),
		{
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${await getAccessToken()}`,
			},
			body: JSON.stringify(data),
		},
	);
}

export function useAddBankAccount(options: Options = {}) {
	return useMutation({
		mutationKey: ["add-bank-account"],
		mutationFn: addBankAccount,
		...options,
	});
}
