import { useCopyToClipboard } from "@uidotdev/usehooks";
import { useEffect, useRef, useState } from "react";
import { CheckIcon } from "../../assets/check";
import { CurlyBracesIcon } from "../../assets/curly-braces";
import { cn } from "../../lib/cn";

type Props = {
	id: string;
};

export function ObjectType({ id }: Props) {
	const [isVisible, setIsVisible] = useState(false);
	const [isCopied, setIsCopied] = useState(false);
	const [_, copyToClipboard] = useCopyToClipboard();

	const timeoutVisibilityRef = useRef<NodeJS.Timeout | null>(null);
	const timeoutCopiedTextRef = useRef<NodeJS.Timeout | null>(null);

	function copy() {
		if (timeoutVisibilityRef.current || timeoutCopiedTextRef.current) {
			return;
		}

		setIsCopied(true);
		copyToClipboard(id);

		timeoutVisibilityRef.current = setTimeout(() => {
			setIsVisible(false);

			timeoutVisibilityRef.current = null;
		}, 1500);

		timeoutCopiedTextRef.current = setTimeout(() => {
			setIsCopied(false);

			timeoutCopiedTextRef.current = null;
		}, 1500 + 150);
	}

	// iome-ignore lint/correctness/useExhaustiveDependencies: clear timeout
	useEffect(() => {
		return () => {
			clearTimeout(timeoutVisibilityRef.current as NodeJS.Timeout);
			clearTimeout(timeoutCopiedTextRef.current as NodeJS.Timeout);
		};
	}, []);

	return (
		<div
			className="relative w-min"
			onMouseEnter={() => setIsVisible(true)}
			onMouseLeave={() => {
				setIsVisible(false);
				clearTimeout(timeoutVisibilityRef.current as NodeJS.Timeout);

				timeoutVisibilityRef.current = null;

				if (timeoutCopiedTextRef.current) {
					clearTimeout(timeoutCopiedTextRef.current as NodeJS.Timeout);
				}

				timeoutCopiedTextRef.current = setTimeout(() => {
					setIsCopied(false);

					timeoutCopiedTextRef.current = null;
				}, 150);
			}}
		>
			<button
				type="button"
				className="flex space-x-1 items-center cursor-pointer"
				onClick={() => copy()}
			>
				<CurlyBracesIcon className="w-4 h-4 fill-neutral-700" />
				<code className="text-sm text-neutral-700 underline decoration-dashed transition-colors mb-px">
					{id}
				</code>
			</button>
			<div
				className={cn(
					"absolute -top-8 left-1/2 -translate-x-1/2 px-1.5 py-0.5 w-min bg-white border border-neutral-200 shadow-sm rounded-lg transition-all duration-150 ease-out",
					isVisible
						? "opacity-100 scale-100"
						: "opacity-0 scale-95 pointer-events-none",
				)}
			>
				{isCopied ? (
					<div className="flex items-center space-x-1">
						<CheckIcon className="w-3 h-3 fill-neutral-700" />
						<span className="text-sm text-neutral-700 whitespace-nowrap">
							Copied
						</span>
					</div>
				) : (
					<div className="flex items-center">
						<span className="text-sm text-neutral-700 whitespace-nowrap">
							Click to copy
						</span>
					</div>
				)}
			</div>
		</div>
	);
}
