import { usePrivy } from "@privy-io/react-auth";
import {
	createRoute,
	useLoaderData,
	useNavigate,
} from "@tanstack/react-router";
import { EditBusinessDetails } from "../../components/settings/edit-business-details";
import { ManageExternalAccounts } from "../../components/settings/manage-external-accounts";
import { Button } from "../../components/ui/button";
import { dashLayout } from "../../layouts/dash";

export const dashSettingsRoute = createRoute({
	getParentRoute: () => dashLayout,
	path: "/settings",
	component: SettingsPage,
});

function SettingsPage() {
	const { user } = useLoaderData({ from: "/dash" });
	const navigate = useNavigate({ from: "/settings" });
	const { ready, logout } = usePrivy();

	return (
		<div className="md:py-6 px-6 md:px-12 w-full flex flex-col space-y-4">
			<h1 className="font-semibold text-2xl">Settings</h1>
			<section className="flex flex-col border border-neutral-200 divide-y divide-neutral-200 rounded-lg bg-white">
				<EditBusinessDetails />
			</section>
			<section className="flex flex-col border border-neutral-200 divide-y divide-neutral-200 rounded-lg bg-white">
				<ManageExternalAccounts />
			</section>
			<section className="flex flex-col border border-neutral-200 divide-y divide-neutral-200 rounded-lg bg-white">
				<div className="p-4 flex justify-between items-center">
					<div>
						<p className="font-medium">Logout</p>
						<span className="text-sm text-neutral-700">
							Clear your session and log out of Inflow.{" "}
							<span>
								You are currently logged in as <strong>{user.email}</strong>.
							</span>
						</span>
					</div>
					<Button
						color="red"
						disabled={!ready}
						onClick={async () => {
							await logout();

							navigate({
								to: "/login",
							});
						}}
					>
						Logout
					</Button>
				</div>
			</section>
			<span className="text-xs text-neutral-200">
				App version: {import.meta.env.MODE}.{import.meta.env.COMMIT_HASH}
			</span>
		</div>
	);
}
