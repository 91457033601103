import {
	type ReactNode,
	createContext,
	useCallback,
	useEffect,
	useState,
} from "react";

type ModalContextType = {
	open: (content: ReactNode) => void;
	close: () => void;
};

export const ModalContext = createContext<ModalContextType | undefined>(
	undefined,
);

export function ModalProvider({ children }: { children: ReactNode }) {
	const [content, setContent] = useState<ReactNode | null>(null);

	function open(content: ReactNode) {
		setContent(content);
	}

	function close() {
		setContent(null);
	}

	const handleKeydown = useCallback((e: KeyboardEvent) => {
		if (e.key === "Escape") {
			e.preventDefault();
			setContent(null);
		}
	}, []);

	useEffect(() => {
		document.addEventListener("keydown", handleKeydown);

		return () => document.removeEventListener("keydown", handleKeydown);
	}, [handleKeydown]);

	return (
		<ModalContext.Provider value={{ open, close }}>
			{children}
			{content && (
				<div className="fixed px-6 sm:px-0 inset-0 flex justify-center items-center bg-black bg-opacity-20 z-50">
					{content}
				</div>
			)}
		</ModalContext.Provider>
	);
}
