export const stablecoinToFiatMapping = {
	USDC: "USD",
	usdc: "USD",
};

function isStablecoin(
	currency: string,
): currency is keyof typeof stablecoinToFiatMapping {
	return currency in stablecoinToFiatMapping;
}

export function numberFormatter(
	value: number,
	{ currency, ...options }: Intl.NumberFormatOptions = {},
) {
	return new Intl.NumberFormat("en-US", {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
		currency:
			currency && isStablecoin(currency)
				? stablecoinToFiatMapping[currency]
				: currency,
		...options,
	}).format(value);
}
