import { getAccessToken } from "@privy-io/react-auth";
import {
	queryOptions,
	useQuery,
	useSuspenseQuery,
} from "@tanstack/react-query";
import { z } from "zod";
import { fetcher } from "../lib/fetcher";
import { linkSchema } from "../schemas/link";
import { productSchema } from "../schemas/product";

async function getLink(id: string) {
	const token = await getAccessToken();

	if (!token) {
		throw new Error("Unauthorized");
	}

	return fetcher(
		`${import.meta.env.VITE_API_URL}/dashboard/links/${id}`,
		linkSchema.extend({
			products: productSchema.array(),
			payments: z
				.object({
					id: z.string(),
					amount: z.number(),
					currency: z.string(),
					email: z.string().nullable(),
					// status: paymentStatusSchema,
					createdAt: z.coerce.date(),
					updatedAt: z.coerce.date(),
				})
				.array(),
		}),
		{
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		},
	);
}

export function linkOptions(id: string) {
	return queryOptions({
		queryKey: ["link", id],
		queryFn: () => getLink(id),
		staleTime: 5 * 1000,
	});
}

export function useLink(id: string) {
	const { data, ...query } = useQuery(linkOptions(id));

	return {
		link: data,
		...query,
	};
}

export function useSuspenseLink(id: string) {
	const { data, ...query } = useSuspenseQuery(linkOptions(id));

	return {
		link: data,
		...query,
	};
}
