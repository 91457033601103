import { getAccessToken } from "@privy-io/react-auth";
import { useMutation } from "@tanstack/react-query";
import { z } from "zod";
import { queryClient } from "../../layouts/_root";
import { fetcher } from "../../lib/fetcher";
import type { LinkStatus } from "../../schemas/link";

export function useUpdateLinkStatus(id: string) {
	return useMutation({
		mutationKey: ["update-status", id],
		mutationFn: async (status: LinkStatus) => {
			return fetcher(
				`${import.meta.env.VITE_API_URL}/dashboard/links/${id}`,
				z.object({
					id: z.string(),
					status: z.string(),
				}),
				{
					method: "PATCH",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${await getAccessToken()}`,
					},
					body: JSON.stringify({ status }),
				},
			);
		},
		onMutate: async (status) => {
			await queryClient.cancelQueries({ queryKey: ["link", id] });

			const prev = queryClient.getQueryData(["link", id]);

			if (typeof prev !== "object") {
				return;
			}

			queryClient.setQueryData(["link", id], () => ({
				...prev,
				status,
			}));

			return { prev };
		},
		onError: (_, __, context) => {
			queryClient.setQueryData(["link", id], context?.prev);

			alert("An error occurred disabling your link. Please try again.");
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: ["link", id] });
		},
	});
}
